import React, { useState, useEffect } from 'react';
import { StyleSheet, View, ScrollView, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation, Link } from '@react-navigation/native';
import aboutHeader from '../../assets/images/services/services11.jpg'
import fonts from '../../config/fonts';
import PageBanner from '../../components/pageBanner';


import ServiceCard from './serviceCard';


import PageData from '../../data/servicePageData';

const { height, width } = Dimensions.get('window')

const Services = () =>
{

    useEffect(() =>
    {
        window.scrollTo(0, 0)
        //firebase.analytics();
        // eslint-disable-next-line
    }, []);

    const navigation = useNavigation();

    return (
        <SafeAreaView>
            <ScrollView>
                <View style={styles.container}>
                    <View style={styles.containerHeaderBG}>


                        <View style={styles.containerHeader}>
                            <Header />
                        </View>
                    </View>

                    <PageBanner
                        cardTopImage={{ uri: aboutHeader }}
                        title={PageData.pageTitleText}
                    />


                    <View style={styles.containerBodyPage}>
                        <Text style={styles.textTitle}>{PageData.title}</Text>
                        <Text style={styles.textHeader}>{PageData.header1}</Text>
                        <Text style={styles.textParagraph}>{PageData.paragraph1}</Text>



                    </View>



                    <View style={width > 1000 ? styles.containerBody : styles.containerBodyMobile}>

                        <ServiceCard
                            onPress={() => navigation.navigate('Service')}
                            cardTopImage={{ uri: PageData.serviceImage01 }}
                            title={PageData.services01}
                            subTitle={PageData.servicesDescription01}
                            size={'small'}
                        />

                        <ServiceCard
                            onPress={() => navigation.navigate('Service')}
                            cardTopImage={{ uri: PageData.serviceImage02 }}
                            title={PageData.services02}
                            subTitle={PageData.servicesDescription01}
                            size={'small'}
                        />
                        <ServiceCard
                            onPress={() => navigation.navigate('Service')}
                            cardTopImage={{ uri: PageData.serviceImage03 }}
                            title={PageData.services03}
                            subTitle={PageData.servicesDescription01}
                            size={'small'}
                        />
                        <ServiceCard
                            onPress={() => navigation.navigate('Service')}
                            cardTopImage={{ uri: PageData.serviceImage04 }}
                            title={PageData.services04}
                            subTitle={PageData.servicesDescription01}
                            size={'small'}
                        />
                        <ServiceCard
                            onPress={() => navigation.navigate('Service')}
                            cardTopImage={{ uri: PageData.serviceImage05 }}
                            title={PageData.services05}
                            subTitle={PageData.servicesDescription01}
                            size={'small'}
                        />


                        <ServiceCard
                            onPress={() => navigation.navigate('Service')}
                            cardTopImage={{ uri: PageData.serviceImage06 }}
                            title={PageData.services06}
                            subTitle={PageData.servicesDescription01}
                            size={'small'}
                        />

                        <ServiceCard
                            onPress={() => navigation.navigate('Service')}
                            cardTopImage={{ uri: PageData.serviceImage07 }}
                            title={PageData.services07}
                            subTitle={PageData.servicesDescription01}
                            size={'small'}
                        />
                        <ServiceCard
                            onPress={() => navigation.navigate('Service')}
                            cardTopImage={{ uri: PageData.serviceImage08 }}
                            title={PageData.services08}
                            subTitle={PageData.servicesDescription01}
                            size={'small'}
                        />
                        <ServiceCard
                            onPress={() => navigation.navigate('Service')}
                            cardTopImage={{ uri: PageData.serviceImage09 }}
                            title={PageData.services09}
                            subTitle={PageData.servicesDescription01}
                            size={'small'}
                        />
                        <ServiceCard
                            onPress={() => navigation.navigate('Service')}
                            cardTopImage={{ uri: PageData.serviceImage10 }}
                            title={PageData.services10}
                            subTitle={PageData.servicesDescription01}
                            size={'small'}
                        />
                    </View>









                    <View style={styles.containerFooter}>
                        <Footer />
                    </View>



                </View>
            </ScrollView>

        </SafeAreaView>

    );
};



const styles = StyleSheet.create({

    container: {
        minHeight: '100vh',
        backgroundColor: colours.greyBG,
    },
    containerHeaderBG: {
        backgroundColor: colours.black
    },

    containerHeader: {

        width: width > 1000 ? 1280 : width,
        alignItems: 'space-around',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        backgroundColor: colours.black
    },

    containerBody: {
        width: width > 1000 ? 1100 : width - 20,
        alignSelf: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'wrap',

    },
    containerBodyMobile: {
        flexDirection: 'column',
        alignSelf: 'center',
    },
    containerBodyPage: {
        alignSelf: 'center',
        marginVertical: 20,
        backgroundColor: colours.blueGreyLight,
        paddingTop: 25,
        paddingBottom: 20,

        width: width > 1000 ? 1100 : width - 20,
        marginHorizontal: 80,
        paddingHorizontal: width > 1000 ? 100 : 25,

    },
    textTitle: {
        paddingTop: 20,
        paddingBottom: 10,
        color: colours.black,
        fontSize: 24,
        fontWeight: '500',
        fontFamily: fonts.primary,
        textAlign: 'justify',
        borderBottomWidth:2, 
        borderColor:colours.brand
    },
    textHeader: {
        paddingTop: 20,
        paddingBottom: 10,
        color: colours.black,
        fontSize: 18,
        fontWeight: '500',
        fontFamily: fonts.primary,
        textAlign: 'justify',
    },
    textParagraph: {
        paddingVertical: 10,
        color: colours.greyDark,
        fontSize: 15,
        lineHeight: 22,
        textAlign: 'justify',
        fontFamily: fonts.primary,
    },
    textSubHeader: {
        paddingTop: 10,
        paddingBottom: 10,
        color: colours.black,
        fontSize: 15,
        fontWeight: '500',
        fontFamily: fonts.primary,
        textAlign: 'justify',
    },
    containerImage: {
        flex: 1,
        height: 300,
        alignSelf: 'center'
    },
    containerImagePortrait: {
        flex: 1,
        height: 300,
    },

    Image: {
        height: 300,
        width: 200,
        resizeMode: 'contain',
        marginHorizontal: 10,
        // marginVertical:width>1000?25:5,  
    },
    TextImageLabel: {
        color: colours.greyDark,
        fontSize: 14,
        textAlign: 'center',
        fontFamily: fonts.primary,
    },
    containerFooter: {
        flex: 1,
        justifyContent: 'flex-end',
    },

});


export default Services;




