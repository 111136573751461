import React from 'react'
//import { Text, View, TouchableOpacity, Dimensions, ImageBackground } from 'react-native'
import { StyleSheet, View, Pressable, Button, Image, Text, TouchableOpacity, Linking, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView, ImageBackground } from 'react-native';

import colours from '../config/colours'
import fonts from '../config/fonts'

const width = Dimensions.get('window').width


const PageBanner = ({ cardTopImage,title,subTitle }) => {


  return (
      <View>


       <View style={styles.containerMain}>

       <View style={styles.containerBanner}>
           <ImageBackground source={cardTopImage}  width={'100%'} style={{ height: width>1000?200:150 }}>
               <ImageBackground width={'100%'} style={{ height: width>1000?200:150, backgroundColor: 'rgba(5,0,0,0.7)' }}>
                   <View style={styles.containerTitle}>
                       <Text style={styles.textTitle}>{title}</Text>
                       <Text style={styles.textSubTitle}>{subTitle}</Text>
                   </View>


               </ImageBackground>
           </ImageBackground>

       </View>

   </View>
   </View>
  )
}


const styles = StyleSheet.create({

    containerMain: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    containerBanner: {
        flex: 1,
        alignSelf: 'center'
    },

    containerTitle: {
        flex: 1,
        justifyContent: 'center',
        alignSelf: 'center',
        maxWidth:800,


    },
    textTitle: {
        color: colours.white,
        fontWeight: '500',
        textAlign:'center',
        fontSize: width>1000?50:22,
        lineHeight:width>1000?60:30,
        marginHorizontal: 10,
        padding: 5,
        fontFamily: fonts.primary
    },
    textSubTitle: {
        marginTop:20,
        color: colours.white,
        fontWeight: '300',
        lineHeight:width>1000?34:20,
        textAlign:'center',
        fontSize: width>1000?22:16,
        marginHorizontal: 10,
        padding: 5,
        fontFamily: fonts.primary
    },
    hovered: {
        backgroundColor: colours.blackSecondary,
        borderRadius: 4,
        //padding:5
    },

});


export default PageBanner