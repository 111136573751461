import React, { useState, useEffect } from 'react';
import { StyleSheet, View, ScrollView, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';
import fonts from '../../config/fonts';
import PageBanner from '../../components/pageBanner';

import PageData from '../../data/servicePageData';


const { height, width } = Dimensions.get('window')

const ServicePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        //firebase.analytics();
        // eslint-disable-next-line
    }, []);

    const navigation = useNavigation();






    return (
        <SafeAreaView>

        <View style={styles.container}>
            <View style={styles.containerHeaderBG}>


                <View style={styles.containerHeader}>
                    <Header />
                </View>
            </View>

            <PageBanner
                cardTopImage={{ uri: PageData.serviceImage01 }}
                title={PageData.service01}
            />


            <View style={styles.containerBodyPage}>
                    <Text style={styles.textTitle}>{PageData.service01}</Text>
                    <Text style={styles.textHeader}>{PageData.serviceDescription01}</Text>
                    <Text style={styles.textParagraph}>{PageData.serviceParagraph01}</Text>

            </View>


            <View style={width > 1000 ? styles.containerBody : styles.containerBodyMobile}>

            </View>
            








            <View style={styles.containerFooter}>
                <Footer />
            </View>

       

        </View>
        </SafeAreaView>

    );
};



const styles = StyleSheet.create({

    container: {
       // minHeight: '100vh',
        backgroundColor: colours.greyBG,
    },
    containerHeaderBG: {
        backgroundColor: colours.black
    },

    containerHeader: {

        width: width > 1000 ? 1280 : width,
        alignItems: 'space-around',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        backgroundColor: colours.black
    },

    containerBody: {
        width: width>1000? 1100:width-20,
        alignSelf:'center',
        justifyContent:'space-between',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
       // alignContent: 'flex-start'
        //justifyContent:'center',
      },
      containerBodyMobile: {
        flexDirection: 'column',
        alignSelf:'center',
      },
    containerBodyPage: {
        alignSelf:'center',
        marginVertical:20,
        backgroundColor: colours.blueGreyLight,
        paddingTop:25,
        paddingBottom:20,

        width: width>1000? 1100:width-20,
        //maxWidth:1280,
        marginHorizontal:80,
        paddingHorizontal:width>1000?100:25,
        //shadowColor: colours.black,
        //shadowOffset: { width: 0, height: 3, },
        //shadowOpacity: 0.2,
        //shadowRadius: 20,
        //elevation: 5,
    },
    textTitle:{
        paddingTop:20,    
        paddingBottom:10,
        color: colours.black,
        fontSize: 24,
        fontWeight: '500',
        fontFamily:fonts.primary,
        textAlign: 'justify',
    },
    textHeader:{
        paddingTop:20,    
        paddingBottom:10,
        color: colours.black,
        fontSize: 18,
        fontWeight: '500',
        fontFamily:fonts.primary,
        textAlign: 'justify',
    },
    textParagraph:{
        paddingVertical:10,    
        color: colours.greyDark ,
        fontSize: 15,
        lineHeight:22,
        textAlign: 'justify',
        fontFamily:fonts.primary,
    },
    textSubHeader:{
        paddingTop:10,
        paddingBottom:10,
        color: colours.black,
        fontSize: 15,
        fontWeight: '500',
        fontFamily:fonts.primary,
        textAlign: 'justify',
    },
    containerImage: {
       flex:1,
        height:300, 
        alignSelf:'center'
    },
    containerImagePortrait: {
        flex:1,
         height:300, 
     },

    Image: {
        height:300,
        width:200,
        resizeMode:'contain',
        marginHorizontal:10,
       // marginVertical:width>1000?25:5,  
    },
    TextImageLabel:{
        color: colours.greyDark ,
        fontSize: 14,
        textAlign: 'center',
        fontFamily:fonts.primary,
    },
    containerFooter: {
        flex: 1,
        justifyContent: 'flex-end',
    },

});


export default ServicePage;




