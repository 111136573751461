import React, { useState, useEffect } from 'react';
import { ScrollView, StyleSheet, View, Image,TextInput, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';
import aboutHeader from '../../assets/images/services/services12.jpg'
import fonts from '../../config/fonts';
import PageBanner from '../../components/pageBanner';
import Modal from 'react-native-modal';

import { firebase,leadfirebase } from '../../config/firebase'






const { height, width } = Dimensions.get('window')

const PMOServices = () =>{



    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        window.scrollTo(0, 0);


        function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
      
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        //firebase.analytics();
        // eslint-disable-next-line
    }, []);

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }
  //const { height, width } = useWindowDimensions();

    const navigation = useNavigation();


    const PageTexts = {
        title1: 'Request A Briefing Call Back',
        paragraph1: 'Please fill your details and we will be contact you soon',
        paragraph2: 'Professional PMO Services \nHigh Return on Investment',
        modal: 'Thank you for your detail \n We will contact you soon.',

    }




    const [onFocus, setOnFocus] = useState(false)

    const [onFocusNumber, setOnFocusNumber] = useState(false)


    const [nameText, setNameText] = useState('')
    const [numberText, setNumberText] = useState('')
    const [messageText, setMessageText] = useState('')

//data
    const [companyName, setCompanyName] = useState('')
    const [companyMobile,setCompanyMobile] = useState('')
    const [companyEmail,setCompanyEmail] = useState('')


//focus

    const [onFocusCompanyName, setOnFocusCompanyName] = useState('')
    const [onFocusCompanyMobile,setOnFocusCompanyMobile] = useState('')
    const [onFocusCompanyEmail,setOnFocusCompanyEmail] = useState('')

    const [modal, setModal] = useState(false)


    // var datess= (new Date()).toISOString()
    // console.log('datetest',datess )

    const contactUsData = () => {

        var currentDate = new Date();
        var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
        var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
        var createdDateTo = currentDate.getFullYear() + twoDigitMonth + twoDigitDate;
        const docid = createdDateTo + "-" + new Date().getTime()


        leadfirebase.firestore().collection('leads')
            .doc(docid).set({
                ad:'PMO Services',
                adset:'PDC Consult',
                category:'New',
                contact:companyMobile,
                name:companyName,
                email:companyEmail,
                creationDt:new Date(),
                date: (new Date()).toISOString(),
                platform:'LinkedIn',
                readstatus:false
            })
            // .then(alert('Details Sent Successfully,\nWe will contact you as soon as possible. \nThank You'))
            .then(setCompanyName(''), setCompanyMobile(''), setCompanyEmail(''))
            .then(setModal(true))
    }

const imageURL = 'https://firebasestorage.googleapis.com/v0/b/pdc-consult.appspot.com/o/promotionAds%2FAd%20Post%20App.jpg?alt=media&token=43997f78-2afd-4868-af9a-2024ef315812'

    return (
        <View style={styles.container}>
            <View style={styles.containerHeaderBG}>


                <View style={styles.containerHeader}>
                    <Header />
                    <Text style={[styles.textParagraph, {textAlign:'center'}]}>{PageTexts.paragraph2}</Text>

                </View>
            </View>

            {/* <PageBanner
                cardTopImage={{ uri: aboutHeader }}
                title={'PMO Services'}
            /> */}


            <View style={styles.containerBodyPage}>
                <Text style={[styles.textHeader,{borderBottomWidth:2, borderColor:colours.brand,}]}>{PageTexts.title1}</Text>
                <Text style={styles.textParagraph}>{PageTexts.paragraph1}</Text>

            </View>






            <View style={styles.containerForm}>

                <View style={styles.textContainer}>
                    
                    <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={companyName}
                        placeholder={onFocus ? '' : 'Type Name ...'}
                        onChangeText={(editedText) => { setCompanyName(editedText) }}
                        onFocus={() => { setOnFocusCompanyName(true) }}
                        onBlur={() => { setOnFocusCompanyName(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocus ? colours.blackSecondary : colours.greyFour}]}
                    />
                      
                      <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={companyMobile}
                        placeholder={onFocusNumber ? '' : 'Type Mobile No ..'}
                        keyboardType='numeric'
                        maxLength={15}
                        onChangeText={(editedText) => { setCompanyMobile(editedText) }}
                        onFocus={() => { setOnFocusCompanyMobile(true) }}
                        onBlur={() => {  setOnFocusCompanyMobile(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocusNumber ? colours.blackSecondary : colours.greyFour}]}
                    />
                    <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={companyEmail}
                        placeholder={onFocus ? '' : 'Type Email..'}
                        onChangeText={(editedText) => { setCompanyEmail(editedText) }}
                        onFocus={() => { setOnFocusCompanyEmail(true) }}
                        onBlur={() => { setOnFocusCompanyEmail(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocus ? colours.blackSecondary : colours.greyFour}]}
                    />

                    <TouchableOpacity
                        onPress={() =>
                        {
                                    companyName.length < 2 ? alert('Please Provide Your Name') :
                                    isNaN(companyMobile) ? alert('Please Provide Valid Mobile Number') :
                                    companyMobile.length<9 ? alert('Please Provide Valid Phone Number') :
                                    companyEmail.length < 4 ? alert('Please Provide Your Email Address') :
                                 contactUsData()
                        }
                        }
                    >
                        <View style={styles.submitButton}>
                            <Text style={styles.textSubmit}>Submit</Text>
                        </View>
                    </TouchableOpacity>
                </View>

                <View style={styles.containerBodyPage}>
                {/* <Text style={styles.textParagraph}>{PageTexts.paragraph2}</Text> */}
                <Image source={{uri:imageURL}} resizeMethod='contain' style={{width:windowDimensions.width-20,height:windowDimensions.width-20, alignSelf:'center'}} />


            </View>

            </View>


         

<Modal
     isVisible={modal} 
     //transparent={true}
    onBackdropPress={()=>setModal(false)}
    animationIn={'fadeIn'}
    animationOut={'fadeOut'}
    style={styles.modalContainer}
    >
<View style={styles.modalView}>

<Text style={styles.textParagraph}>{PageTexts.modal}</Text>


<TouchableOpacity
                        onPress={() =>setModal(false)}
                    >
                        <View style={[styles.submitButton,{alignSelf:'center'}]}>
                            <Text style={styles.textSubmit}>Close</Text>
                        </View>
                    </TouchableOpacity>



</View>

</Modal>



            <View style={styles.containerFooter}>
                <Footer />
            </View>



        </View>

    );
};



const styles = StyleSheet.create({

    container: {
       // minHeight: '100vh',
        backgroundColor: colours.greyBG,
    },
    containerHeaderBG: {
        backgroundColor: colours.black
    },

    containerHeader: {

        width: width > 1000 ? 1280 : width,
        alignItems: 'space-around',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        backgroundColor: colours.black
    },

    containerBody: {
        //flex: 1,
        paddingVertical: width > 1000 ? 20 : 5,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignSelf: 'center',
        alignItems: 'center',
        width: width > 1000 ? 1280 : width,
    },
    containerBodyPage: {
        alignSelf: 'center',
        marginTop: 20,
        backgroundColor: colours.blueGreyLight,
        paddingTop: 25,
        paddingBottom: 20,

        width: width > 1000 ? 1100 : width - 20,
        //maxWidth:1280,
        marginHorizontal: 80,
        paddingHorizontal: width > 1000 ? 100 : 25,
        //shadowColor: colours.black,
        //shadowOffset: { width: 0, height: 3, },
        //shadowOpacity: 0.2,
        //shadowRadius: 20,
        //elevation: 5,
    },
    textHeader: {
        paddingTop: 20,
        paddingBottom: 10,
        color: colours.black,
        fontSize: 18,
        fontWeight: '500',
        fontFamily: fonts.primary,
        textAlign: 'justify',
    },
    textParagraph: {
        paddingVertical: 10,
        color: colours.greyDark,
        fontSize: 15,
        lineHeight: 22,
        textAlign: 'justify',
        fontFamily: fonts.primary,
    },
    textSubHeader: {
        paddingTop: 10,
        paddingBottom: 10,
        color: colours.black,
        fontSize: 15,
        fontWeight: '500',
        fontFamily: fonts.primary,
        textAlign: 'justify',
    },
    containerImage: {
        flex: 1,
        height: 300,
        alignSelf: 'center'
    },
    containerImagePortrait: {
        flex: 1,
        height: 300,
    },

    Image: {
        height: 300,
        width: 200,
        resizeMode: 'contain',
        marginHorizontal: 10,
        // marginVertical:width>1000?25:5,  
    },
    TextImageLabel: {
        color: colours.greyDark,
        fontSize: 14,
        textAlign: 'center',
        fontFamily: fonts.primary,
    },
    containerForm: {
        width: width > 1000 ? 1100 : width - 20,

        alignSelf: 'center',
        marginBottom:20,
        paddingBottom:10,
        backgroundColor: colours.blueGreyLight,


    },

    textContainer: {
        paddingTop: 10,
        marginHorizontal: 10,
        alignItems: 'center',
        backgroundColor: colours.blueGreyLight,

    },

    textSubmit: {
        fontSize: 20,
        color: colours.white,
    },

    submitButton: {
        backgroundColor: colours.brandDark,
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
        marginTop: 10,
        borderRadius:5,

    },
    containerFooter: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    textInputStyle:{
        minWidth: width > 1000 ? 700:width-30,
        //maxWidth: '100%',
        textAlign: 'left',
        fontFamily: fonts.primary,
        marginBottom: 10,
        marginTop: 2,
        color: colours.greyDark,
        fontSize: '.8rem',
        fontWeight: '500',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10
    },
    modalView: {
        justifyContent: 'space-around',
        alignItems: 'center',
        height: height / 2.5,
        backgroundColor: colours.black,
        borderRadius: 20,
        marginHorizontal: 2,
        padding:20
      },

});


export default PMOServices;




