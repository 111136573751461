//import * as firebase from 'firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCbRCTS5zxbYjoTNTU216VjYdys-V-Rwv8",
  authDomain: "pdc-consult.firebaseapp.com",
  projectId: "pdc-consult",
  storageBucket: "pdc-consult.appspot.com",
  messagingSenderId: "870430821474",
  appId: "1:870430821474:web:dfe9aa0343de160c9f553b",
  measurementId: "G-CP90N05HSG"
};

const firebaseConfig2 = {
  apiKey: "AIzaSyCfo9cZERVmiDb86XS9YmnN0cHs2dDqArM",
  authDomain: "pdc-interiors.firebaseapp.com",
  databaseURL: "https://pdc-interiors.firebaseio.com",
  projectId: "pdc-interiors",
  storageBucket: "pdc-interiors.appspot.com",
  messagingSenderId: "754162203554",
  appId: "1:754162203554:web:5492cb7b81a4f021e790fb",
  measurementId: "G-Z7LLK8F5NZ"
};


// if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  var leadfirebase = firebase.initializeApp(firebaseConfig2,'pdc-interiors');

// }

export { firebase,leadfirebase };