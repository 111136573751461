import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';
import fonts from '../../config/fonts';
import PageBanner from '../../components/pageBanner';


import dubai from '../../assets/images/home/dubai.jpg'
import london from '../../assets/images/home/london.jpg'
import cairo from '../../assets/images/home/cairo.jpg'
import ContactCard from './contactCard';
import ContactDetails from './contactDetails';
import ContactSocial from './contactSocial';



const { height, width } = Dimensions.get('window')

const Contact = () =>
{


    useEffect(() => {
        window.scrollTo(0, 0)
        //firebase.analytics();
        // eslint-disable-next-line
    }, []);

    const navigation = useNavigation();

    return (
        <View style={styles.container}>
            <View style={styles.containerHeaderBG}>


                <View style={styles.containerHeader}>
                    <Header />
                </View>
            </View>

            <PageBanner
                cardTopImage={{ uri: dubai }}
                title={'Contact'}
            />






            <View style={styles.containerBodyMain}>



                <View style={width > 1000 ? styles.containerbox : styles.containerboxMobile}>
                    <View style={styles.containerInsideHeader}>
                        <Text style={styles.textHeader}>{'Register with us'}</Text>
                    </View>
                    <View style={styles.containerRegister}>

                        <Text style={styles.textParagraph}>{'Participate into our Tenders, We are inviting Consultants, Contractors, Subcontractors\n\n'}</Text>

                        <TouchableOpacity onPress={() => navigation.navigate('Register') }>
                            <View style={styles.submitButton}>
                                <Text style={styles.textSubmit}>Register</Text>
                            </View>
                        </TouchableOpacity>
                        <Text style={[styles.textParagraph,{paddingHorizontal:10}]}>{'\nPlease register your company in order to participate into our current and upcoming Tenders.'}</Text>


                    </View>

                </View>







                <View style={width > 1000 ? styles.containerbox : styles.containerboxMobile}>
                    <View style={styles.containerInsideHeader}>
                        <Text style={styles.textHeader}>{'Connect with us'}</Text>
                    </View>

                    <View style={styles.containerRightInside}>
                        <ContactSocial />
                    </View>
                </View>

                <View style={width > 1000 ? styles.containerbox : styles.containerboxMobile}>
                    <View style={styles.containerInsideHeader}>
                        <Text style={styles.textHeader}>{'Leave a message'}</Text>
                    </View>

                    <View style={styles.containerRightInside}>
                        <ContactDetails />
                    </View>
                </View>

            </View>








            <View style={styles.containerBodyMain}>
                <View style={styles.containerLeft}>
                    <View style={[styles.containerInsideHeaderRight,{marginBottom:width > 1000 ?0:15}]}>
                        <Text style={styles.textHeader}>{'Head Office'}</Text>
                    </View>
                    <View style={styles.containerLeftInside}>

                        <ContactCard
                            cardTopImage={{ uri: dubai }}
                            title={'Dubai, UAE'}
                            subTitle={'Burj Khalifa District, \nBusiness Bay, Suite 2606, \nDubai, United Arab Emirates'}
                            size={'small'}
                        />
                    </View>
                </View>

                <View style={styles.containerRight}>
                    <View style={styles.containerInsideHeaderRight}>
                        <Text style={styles.textHeader}>{'Worldwide Offices'}</Text>
                    </View>

                    <View style={styles.containerRightInside}>
                        <ContactCard
                            cardTopImage={{ uri: cairo }}

                            title={'Cairo, EGYPT'}
                            subTitle={'Nile Tower,\nCairo, Egypt'}
                            size={'small'}
                        />
                        <ContactCard
                            cardTopImage={{ uri: london }}
                            title={'London, UK'}
                            subTitle={'27 Old Gloucester Street, \nLondon, United Kingdom, \nWC1N 3AX'}
                            size={'small'}
                        />
                    </View>
                </View>

            </View>






            <View style={styles.containerFooter}>
                <Footer />
            </View>

        </View>

    );
};



const styles = StyleSheet.create({

    container: {
        backgroundColor: colours.greyBG,
    },
    containerHeaderBG: {
        backgroundColor: colours.black
    },

    containerHeader: {
        width: width > 1000 ? 1280 : width,
        alignItems: 'space-around',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        backgroundColor: colours.black
    },
    containerBodyMain: {
        width: width > 1280 ? 1280 : width,
        flexDirection: width > 1000 ? 'row' : 'column',
        paddingVertical: 20,
        flexWrap: width > 1000 ? 'wrap' : 'nowrap',
        alignSelf: 'center',
    },
    containerLeft: {
        flex: 1,
        marginVertical: 10,
        paddingVertical: 10,
    },
    containerLeftInside: {
        flex: 1,
        flexDirection: width > 1000 ? 'row' : 'column',
        justifyContent: 'center',
        //marginVertical:width > 1000 ?0:50
    },
    containerRight: {
        flex: 2,
        marginVertical: 10,
        paddingVertical: 10,
    },
    containerbox: {
        flex: 1,
        //marginVertical: 10,
        //paddingVertical:10,
    },
    containerboxMobile: {
        alignSelf: 'center',
        marginVertical: 20,
    },
    containerInsideHeader: {
        width: width > 1000 ? 400 : width - 20,
        borderBottomWidth:2,
        borderColor:colours.brand,
        alignSelf: 'center',
        //marginHorizontal:20,
        backgroundColor: colours.blueGreyLight,
    },
    containerInsideHeaderRight: {
        borderBottomWidth:2,
        borderColor:colours.brand,
        marginHorizontal:width > 1000 ?  16:10,
        backgroundColor: colours.blueGreyLight,
    },
    textHeader: {
        padding: 10,
        color: colours.black,
        fontSize: 20,
        fontWeight: '500',
        fontFamily: fonts.primary,
        textAlign: 'justify',
    },
    containerRightInside: {
        flex: 1,
        justifyContent: 'center',
        flexDirection: width > 1000 ? 'row' : 'column',
    },
    containerFooter: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    containerRegister: {
        width: width > 1000 ? 400 : width - 20,
        marginTop: 10,
        paddingBottom: 10,
        alignItems: 'center',
        marginHorizontal: 14,

        backgroundColor: colours.blueGreyLight,

        shadowColor: colours.black,
        shadowOffset: { width: 0, height: 2, },
        shadowOpacity: 0.1,
        shadowRadius: 20,
        elevation: 3,
    },

    textSubmit: {
        fontSize: 20,
        color: colours.white,
        fontFamily:fonts.primary
    },

    submitButton: {
        backgroundColor: colours.brandDark,
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
        marginTop: 10,
        borderRadius: 5,

    },
    textParagraph: {
        paddingVertical: 10,
        color: colours.greyDark,
        fontSize: 15,
        lineHeight: 22,
        textAlign: 'center',
        fontFamily: fonts.primary,
    },
});


export default Contact;




