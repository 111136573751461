import React from 'react';
import { StyleSheet,View,Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView,SafeAreaView , Linking} from 'react-native';

import colours from '../config/colours';
import fonts from '../config/fonts'
import FooterWidgets from './footerWidgets';


const { height, width } = Dimensions.get('window')

const Footer = () => {
 
    return (
      <View>
      <FooterWidgets />

      <View style={styles.container}>

      <Text numberOfLines={1} style={styles.footerText} >
                        © Copyright  {new Date().getFullYear()} | PDC CONSULT 
                    </Text>
      

                    <Text numberOfLines={1} style={styles.footerText} >
                        All rights reserved | Memeber of <TouchableOpacity 
                onPress={() => Linking.openURL('https://pdcgroupconsulting.com')}

                    >
                  <Text style={styles.footerLink}>PDC Group</Text>
                </TouchableOpacity>
                    </Text>

      </View>
      </View>

    );
  };



const styles = StyleSheet.create({
  container: {
    backgroundColor: colours.greyThird,
    paddingTop:25,
    paddingBottom:40,

  },
  footerText: {
    fontSize: width>1000?14:10,
    color: colours.blackSecondary,
    textAlign: 'center',
    fontWeight: '300',
    fontFamily:fonts.primary
  },

  footerLink: {
    fontSize: width>1000?14:10,
    fontWeight: '500',
    fontFamily:fonts.primary
  },


});
  

export default Footer;