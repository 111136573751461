import React from 'react'
import { Text, View, TouchableOpacity, Dimensions, ImageBackground } from 'react-native'
import colours from '../config/colours'
import fonts from '../config/fonts'
import cardTop from '../assets/images/home/cartop-pmo.jpg'

const width = Dimensions.get('window').width


const HomeCardUpdates = ({ cardTopImage, title, subTitle, onPress, type = 'filled', bordered = false, size = 'large' }) =>
{
    const large = width > 1000 ? 800 : width-20
    const small = width > 1000 ? 400 : width-20
    const btnSize = size === 'large' ? large : small
    const btnBgColor = type === 'filled' ? colours.blueGreyLight : 'transparent'
    const btnTextColor = type === 'filled' ? colours.black : colours.white
    //const btnBorderRadius = bordered ? 30 : 5

    const containerCommonStyle = {
        borderTopWidth:2,
        borderColor:colours.brandLight,
        backgroundColor: btnBgColor,
        width: btnSize,
        //marginHorizontal: 10,
        //marginTop: 10,
        //height: 200,
        shadowColor: colours.black,
        shadowOffset: { width: 0, height: 3, },
        shadowOpacity: 0.2,
        shadowRadius: 20,
        elevation: 5,
    }

    const topBar = {
        backgroundColor: colours.black,
        height: 70,
    }


    const titleCommonStyle = {
        paddingTop: 15,
        paddingHorizontal: 20,
        paddingBottom: 5,
        color: btnTextColor,
        fontSize: 18,
        fontWeight: '500',
        fontFamily: fonts.primary,
        textAlign: 'left',
    }
    const subTitleCommonStyle = {
        paddingTop: 5,
        paddingHorizontal: 20,
        paddingBottom: 15,
        color: colours.greyDark,
        fontSize: 15,
        textAlign: 'left',
        fontFamily: fonts.primary,

    }




    const border = type === 'outlined' && { borderColor: '#e7e7e7', borderWidth: 2 }

    return (
        <TouchableOpacity onPress={onPress} activeOpacity={0.7}>
            <View style={[containerCommonStyle, border]}>

                <Text style={[titleCommonStyle]}>{title}</Text>
                <Text  style={[subTitleCommonStyle]}>{subTitle}</Text>
            </View>
        </TouchableOpacity>
    )
}

export default HomeCardUpdates