import React, { useState, useEffect } from 'react';
import { ScrollView, StyleSheet, View, Image,TextInput, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';
import aboutHeader from '../../assets/images/services/services12.jpg'
import fonts from '../../config/fonts';
import PageBanner from '../../components/pageBanner';

import { firebase } from '../../config/firebase'





const { height, width } = Dimensions.get('window')

const Register = () =>
{

    useEffect(() => {
        window.scrollTo(0, 0)
        //firebase.analytics();
        // eslint-disable-next-line
    }, []);

    const navigation = useNavigation();


    const PageTexts = {
        title1: 'Registration for Tender Invitations',
        paragraph1: 'In order to participate into our Tenders, please register your company with needed details. Once received, we will review and start communicating with you. Please make sure to provide correct details for communications. We will send you email for further requirements if any. \n\nFor any assistance you may contact us at procurement@pdcconsult.com\nYou may also send following details to register with us.',
        paragraph2: 'Note: Please make sure to provide correct details. \nEmail: procurement@pdcconsult.com',

    }




    const [onFocus, setOnFocus] = useState(false)

    const [onFocusNumber, setOnFocusNumber] = useState(false)

    const [onFocusMessage, setOnFocusMessage] = useState(false)

    const [nameText, setNameText] = useState('')
    const [numberText, setNumberText] = useState('')
    const [messageText, setMessageText] = useState('')

//data
    const [companyName, setCompanyName] = useState('')
    const [companyPhone,setCompanyPhone] = useState('')
    const [companyMobile,setCompanyMobile] = useState('')
    const [companyAddress,setCompanyAddress] = useState('')
    const [companyWebsite,setCompanyWebsite] = useState('')
    const [companyEmail,setCompanyEmail] = useState('')

    const [contactPersonName,setContactPersonName] = useState('')
    const [contactPersonDesignation,setContactPersonDesignation] = useState('')
    const [contactPersonPhone,setContactPersonPhone] = useState('')
    const [contactPersonEmail,setContactPersonEmail] = useState('')

    const [companyTradeLicenseNumber,setCompanyTradeLicenseNumber] = useState('')
    const [companyVATno,setCompanyVATno] = useState('')

    const [companyServices,setCompanyServices] = useState('')
    const [companyProfile,setCompanyProfile] = useState('')
//focus

    const [onFocusCompanyName, setOnFocusCompanyName] = useState('')
    const [onFocusCompanyPhone,setOnFocusCompanyPhone] = useState('')
    const [onFocusCompanyMobile,setOnFocusCompanyMobile] = useState('')
    const [onFocusCompanyAddress,setOnFocusCompanyAddress] = useState('')
    const [onFocusCompanyWebsite,setOnFocusCompanyWebsite] = useState('')
    const [onFocusCompanyEmail,setOnFocusCompanyEmail] = useState('')

    const [onFocusContactPersonName,setOnFocusContactPersonName] = useState('')
    const [onFocusContactPersonDesignation,setOnFocusContactPersonDesignation] = useState('')
    const [onFocusContactPersonPhone,setOnFocusContactPersonPhone] = useState('')
    const [onFocusContactPersonEmail,setOnFocusContactPersonEmail] = useState('')

    const [onFocusCompanyTradeLicenseNumber,setOnFocusCompanyTradeLicenseNumber] = useState('')
    const [onFocusCompanyVATno,setOnFocusCompanyVATno] = useState('')

    const [onFocusCompanyServices,setOnFocusCompanyServices] = useState('')
    const [onFocusCompanyProfile,setOnFocusCompanyProfile] = useState('')



    const [subHeaderTextHeight, setSubHeaderTextHeight] = useState(0)


    const contactUsData = () => {

        var currentDate = new Date();
        var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
        var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
        var createdDateTo = currentDate.getFullYear() + twoDigitMonth + twoDigitDate;
        const docid = createdDateTo + "-" + new Date().getTime()



        firebase.firestore().collection('procurement')
            .doc(docid).set({
                companyName:companyName,
                companyPhone:companyPhone,
                companyMobile:companyMobile,
                companyAddress:companyAddress,
                companyWebsite:companyWebsite,
                companyEmail:companyEmail,
                contactPersonName:contactPersonName,
                contactPersonDesignation:contactPersonDesignation,
                contactPersonPhone:contactPersonPhone,
                contactPersonEmail:contactPersonEmail,
                companyTradeLicenseNumber:companyTradeLicenseNumber,
                companyVATno:companyVATno,
                companyServices:companyServices,
                companyProfile:companyProfile,
                message: messageText,
                createdDtm: new Date()
            })
            .then(alert('Details Sent Successfully,\nWe will contact you as soon as possible. \nThank You'))
            .then(setNameText(''), setNumberText(''), setMessageText(''))
    }



    return (
        <View style={styles.container}>
            <View style={styles.containerHeaderBG}>


                <View style={styles.containerHeader}>
                    <Header />
                </View>
            </View>

            <PageBanner
                cardTopImage={{ uri: aboutHeader }}
                title={'Registration'}
            />


            <View style={styles.containerBodyPage}>
                <Text style={[styles.textHeader,{borderBottomWidth:2, borderColor:colours.brand}]}>{PageTexts.title1}</Text>
                <Text style={styles.textParagraph}>{PageTexts.paragraph1}</Text>

            </View>






            <View style={styles.containerForm}>

                <View style={styles.textContainer}>
                    
                    <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={companyName}
                        placeholder={onFocus ? '' : 'Comapny Name*'}
                        onChangeText={(editedText) => { setCompanyName(editedText) }}
                        onFocus={() => { setOnFocusCompanyName(true) }}
                        onBlur={() => { setOnFocusCompanyName(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocus ? colours.blackSecondary : colours.greyFour}]}
                    />
                      <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={companyPhone}
                        placeholder={onFocusNumber ? '' : 'Company Phone Number*'}
                        keyboardType='numeric'
                        maxLength={15}
                        onChangeText={(editedText) => { setCompanyPhone(editedText) }}
                        onFocus={() => { setOnFocusCompanyPhone(true) }}
                        onBlur={() => {  setOnFocusCompanyPhone(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocusNumber ? colours.blackSecondary : colours.greyFour}]}
                    />
                      <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={companyMobile}
                        placeholder={onFocusNumber ? '' : 'Company Mobile Number'}
                        keyboardType='numeric'
                        maxLength={15}
                        onChangeText={(editedText) => { setCompanyMobile(editedText) }}
                        onFocus={() => { setOnFocusCompanyMobile(true) }}
                        onBlur={() => {  setOnFocusCompanyMobile(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocusNumber ? colours.blackSecondary : colours.greyFour}]}
                    />
                    <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={companyAddress}
                        placeholder={onFocus ? '' : 'Comapny Address'}
                        onChangeText={(editedText) => { setCompanyAddress(editedText) }}
                        onFocus={() => { setOnFocusCompanyAddress(true) }}
                        onBlur={() => { setOnFocusCompanyAddress(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocus ? colours.blackSecondary : colours.greyFour}]}
                    />

                    <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={companyWebsite}
                        placeholder={onFocus ? '' : 'Comapny Website'}
                        onChangeText={(editedText) => { setCompanyWebsite(editedText) }}
                        onFocus={() => { setOnFocusCompanyWebsite(true) }}
                        onBlur={() => { setOnFocusCompanyWebsite(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocus ? colours.blackSecondary : colours.greyFour}]}
                    />
                    <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={companyEmail}
                        placeholder={onFocus ? '' : 'Comapny Email*'}
                        onChangeText={(editedText) => { setCompanyEmail(editedText) }}
                        onFocus={() => { setOnFocusCompanyEmail(true) }}
                        onBlur={() => { setOnFocusCompanyEmail(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocus ? colours.blackSecondary : colours.greyFour}]}
                    />
                    <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={contactPersonName}
                        placeholder={onFocus ? '' : 'Contact Person Name*'}
                        onChangeText={(editedText) => { setContactPersonName(editedText) }}
                        onFocus={() => { setOnFocusContactPersonName(true) }}
                        onBlur={() => { setOnFocusContactPersonName(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocus ? colours.blackSecondary : colours.greyFour}]}
                    />
                    <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={contactPersonDesignation}
                        placeholder={onFocus ? '' : 'Contact Person Designation'}
                        onChangeText={(editedText) => { setContactPersonDesignation(editedText) }}
                        onFocus={() => { setOnFocusContactPersonDesignation(true) }}
                        onBlur={() => { setOnFocusContactPersonDesignation(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocus ? colours.blackSecondary : colours.greyFour}]}
                    />

                    <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={contactPersonPhone}
                        placeholder={onFocusNumber ? '' : 'Contact Person Phone/Mobile Number*'}
                        keyboardType='numeric'
                        maxLength={15}
                        onChangeText={(editedText) => { setContactPersonPhone(editedText) }}
                        onFocus={() => { setOnFocusContactPersonPhone(true) }}
                        onBlur={() => {  setOnFocusContactPersonPhone(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocusNumber ? colours.blackSecondary : colours.greyFour}]}
                    />  

                    <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={contactPersonEmail}
                        placeholder={onFocus ? '' : 'Contact Person Email*'}
                        onChangeText={(editedText) => { setContactPersonEmail(editedText) }}
                        onFocus={() => { setOnFocusContactPersonEmail(true) }}
                        onBlur={() => { setOnFocusContactPersonEmail(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocus ? colours.blackSecondary : colours.greyFour}]}
                    />

                    <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={companyTradeLicenseNumber}
                        placeholder={onFocusNumber ? '' : 'Company Trade License Number*'}
                        keyboardType='numeric'
                        maxLength={25}
                        onChangeText={(editedText) => { setCompanyTradeLicenseNumber(editedText) }}
                        onFocus={() => { setOnFocusCompanyTradeLicenseNumber(true) }}
                        onBlur={() => {  setOnFocusCompanyTradeLicenseNumber(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocusNumber ? colours.blackSecondary : colours.greyFour}]}
                    />



                <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={companyVATno}
                        placeholder={onFocusNumber ? '' : 'Company VAT Registration(TRN) Number*'}
                        keyboardType='numeric'
                        maxLength={20}
                        onChangeText={(editedText) => { setCompanyVATno(editedText) }}
                        onFocus={() => { setOnFocusCompanyVATno(true) }}
                        onBlur={() => {  setOnFocusCompanyVATno(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocusNumber ? colours.blackSecondary : colours.greyFour}]}
                    />


                   <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={companyServices}
                        placeholder={onFocus ? '' : 'Company Services / Products list'}
                        onChangeText={(editedText) => { setCompanyServices(editedText) }}
                        onFocus={() => { setOnFocusCompanyServices(true) }}
                        onBlur={() => { setOnFocusCompanyServices(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocus ? colours.blackSecondary : colours.greyFour}]}
                    />

                    <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={companyProfile}
                        placeholder={onFocus ? '' : 'Company Profile PDF Link (wetransfer/dropbox) - If available'}
                        onChangeText={(editedText) => { setCompanyProfile(editedText) }}
                        onFocus={() => { setOnFocusCompanyProfile(true) }}
                        onBlur={() => { setOnFocusCompanyProfile(false) }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{borderColor: onFocus ? colours.blackSecondary : colours.greyFour}]}
                    />


                    <TextInput
                        adjustsFontSizeToFit={true}
                        allowFontScaling={true}
                        value={messageText}
                        placeholder={onFocusMessage ? '' : 'Message/Notes (if any)'}
                        multiline={true}

                        onContentSizeChange={(event) => { setSubHeaderTextHeight(event.nativeEvent.contentSize.height) }}
                        onChangeText={(editedText) => { setMessageText(editedText) }}
                        onFocus={() => { setOnFocusMessage(true) }}
                        onBlur={() => {  setOnFocusMessage(false) }}
                        onKeyPress={(nativeEvent) =>
                        {
                            if (nativeEvent.key === 'Backspace') {
                                //setSubHeaderTextHeight(0) 
                            }
                        }}
                        placeholderTextColor={colours.greyFive}
                        style={[styles.textInputStyle,{
                            borderColor: onFocusMessage ? colours.blackSecondary : colours.greyFour,
                            height: Math.max(20, subHeaderTextHeight),
                            minHeight: Math.max(20, 100)
                        }]}

                      
                    />

                    <TouchableOpacity
                        onPress={() =>
                        {
                                    companyName.length < 3 ? alert('Please Provide Company Name') :
                                    isNaN(companyPhone) ? alert('Please Provide Company Phone Number') :
                                    companyPhone.length<5 ? alert('Please Provide Company Phone Number') :

                                    companyEmail.length < 4 ? alert('Please Provide Company Email Address') :
                                    contactPersonName.length < 3 ? alert('Please Provide Contact Person Name') :
                                    isNaN(contactPersonPhone) ? alert('Please Provide Contact Person Phone Number') :

                                    contactPersonPhone.length < 9 ? alert('Please Provide Contact Person Phone Number') :
                                    contactPersonEmail.length < 3 ? alert('Please Provide Contact Person Email Address') :
                                    isNaN(companyTradeLicenseNumber) ? alert('Please Provide Company Trade License Number') :
                                    companyTradeLicenseNumber.length < 5 ? alert('Please Provide Company Trade License Number') :

                                    isNaN(companyVATno) ? alert('Please Provide Company VAT Certificate/Registration (TRN) Number') :
                                    companyVATno.length < 10 ? alert('Please Provide Company VAT Certificate/Registration (TRN) Number') :

                                 contactUsData()

                        }
                        }
                    >
                        <View style={styles.submitButton}>
                            <Text style={styles.textSubmit}>Submit</Text>
                        </View>
                    </TouchableOpacity>
                </View>

                <View style={styles.containerBodyPage}>
                <Text style={styles.textParagraph}>{PageTexts.paragraph2}</Text>

            </View>

            </View>


         



            <View style={styles.containerFooter}>
                <Footer />
            </View>



        </View>

    );
};



const styles = StyleSheet.create({

    container: {
       // minHeight: '100vh',
        backgroundColor: colours.greyBG,
    },
    containerHeaderBG: {
        backgroundColor: colours.black
    },

    containerHeader: {

        width: width > 1000 ? 1280 : width,
        alignItems: 'space-around',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        backgroundColor: colours.black
    },

    containerBody: {
        //flex: 1,
        paddingVertical: width > 1000 ? 20 : 5,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignSelf: 'center',
        alignItems: 'center',
        width: width > 1000 ? 1280 : width,
    },
    containerBodyPage: {
        alignSelf: 'center',
        marginTop: 20,
        backgroundColor: colours.blueGreyLight,
        paddingTop: 25,
        paddingBottom: 20,

        width: width > 1000 ? 1100 : width - 20,
        //maxWidth:1280,
        marginHorizontal: 80,
        paddingHorizontal: width > 1000 ? 100 : 25,
        //shadowColor: colours.black,
        //shadowOffset: { width: 0, height: 3, },
        //shadowOpacity: 0.2,
        //shadowRadius: 20,
        //elevation: 5,
    },
    textHeader: {
        paddingTop: 20,
        paddingBottom: 10,
        color: colours.black,
        fontSize: 18,
        fontWeight: '500',
        fontFamily: fonts.primary,
        textAlign: 'justify',
    },
    textParagraph: {
        paddingVertical: 10,
        color: colours.greyDark,
        fontSize: 15,
        lineHeight: 22,
        textAlign: 'justify',
        fontFamily: fonts.primary,
    },
    textSubHeader: {
        paddingTop: 10,
        paddingBottom: 10,
        color: colours.black,
        fontSize: 15,
        fontWeight: '500',
        fontFamily: fonts.primary,
        textAlign: 'justify',
    },
    containerImage: {
        flex: 1,
        height: 300,
        alignSelf: 'center'
    },
    containerImagePortrait: {
        flex: 1,
        height: 300,
    },

    Image: {
        height: 300,
        width: 200,
        resizeMode: 'contain',
        marginHorizontal: 10,
        // marginVertical:width>1000?25:5,  
    },
    TextImageLabel: {
        color: colours.greyDark,
        fontSize: 14,
        textAlign: 'center',
        fontFamily: fonts.primary,
    },
    containerForm: {
        width: width > 1000 ? 1100 : width - 20,

        alignSelf: 'center',
        marginBottom:20,
        paddingBottom:10,
        backgroundColor: colours.blueGreyLight,


    },

    textContainer: {
        paddingTop: 10,
        marginHorizontal: 10,
        alignItems: 'center',
        backgroundColor: colours.blueGreyLight,

    },

    textSubmit: {
        fontSize: 20,
        color: colours.white,
    },

    submitButton: {
        backgroundColor: colours.brandDark,
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
        marginTop: 10,
        borderRadius:5,

    },
    containerFooter: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    textInputStyle:{
        minWidth: width > 1000 ? 700:width-30,
        //maxWidth: '100%',
        textAlign: 'left',
        fontFamily: fonts.primary,
        marginBottom: 10,
        marginTop: 2,
        color: colours.greyDark,
        fontSize: '.8rem',
        fontWeight: '500',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10
    }

});


export default Register;




