
import React from 'react';



import { NavigationContainer, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';


import Home from './home/home' 
import About from './about/about';
import Contact from './contact/contact';
import Services from './services/services';
import ServicePage from './services/servicePage';
import Register from './contact/register';
import LeadsForm from './promotions/leadsForm';
import PMOServices from './promotions/pmoServices';




const HomeStack = createNativeStackNavigator();
function HomeStackScreen({route, navigation}) {


  return (
    <HomeStack.Navigator screenOptions={{ headerShown: false}}>
      <HomeStack.Screen name='Home' component={Home} options={{headerShown: false,title:'PDC Consult'}}/>
      <HomeStack.Screen name='About' component={About} options={{headerShown: false}}/>
      <HomeStack.Screen name='Contact' component={Contact} options={{headerShown: false}}/>
      <HomeStack.Screen name='Services' component={Services} options={{headerShown: false}}/>
      <HomeStack.Screen name='Service' component={ServicePage} options={{headerShown: false}}/>
      <HomeStack.Screen name='Register' component={Register} options={{headerShown: false}}/>
      <HomeStack.Screen name='PMOServices' component={PMOServices} options={{headerShown: false}}/>
      <HomeStack.Screen name='Consultation' component={LeadsForm} options={{headerShown: false}}/>

    </HomeStack.Navigator>
  );
}

const linking = {
  prefixes: ['https://pdcconsult.com', 'pdcconsult://','localhost:3000'],
  config: {
    screens: {
      Home: '',
      About: 'about',
      Contact: 'contact',
      Services: 'services',
      Service: 'service',
      Register: 'register',
      Consultation: 'consultation',
      PMOServices: 'pmoservices',

    }
  },
};


function HomeNavigator() {
  
  return (
    
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>} theme={DarkTheme}>
      <HomeStackScreen />
    </NavigationContainer>
    
  )
}

export default HomeNavigator;
