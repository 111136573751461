import React, { useEffect } from 'react';
import { StyleSheet, View, Text, Dimensions,Image,TouchableOpacity } from 'react-native';

import colours from '../../config/colours';

import { useNavigation } from '@react-navigation/native';
import fonts from '../../config/fonts';
import WhatsApp from '../../assets/images/social/Whatsapp.svg';

import Ad from '../../assets/images/promotion/ad-web-vill-01.jpg';


import LeadFormFill from './leadFormFill';
import LeadFormConnect from './leadFormConnect';
import LeadFormFooter from './leadFormFooter';
import LeadFormHeader from './leadFormHeader';
//import firebase from 'firebase/compat'
import { firebase,leadfirebase } from '../../config/firebase'


const { height, width } = Dimensions.get('window')

const LeadsForm = () =>
{


    useEffect(() => {
        window.scrollTo(0, 0)
        firebase.analytics();
        // eslint-disable-next-line
    }, []);

    const navigation = useNavigation();

    return (
        <View style={styles.container}>
            <View style={styles.containerHeaderBG}>


                <View style={styles.containerHeader}>
                    <LeadFormHeader />
                </View>
            </View>
{/* 
            <PageBanner
                cardTopImage={{ uri: dubai }}
                title={'Consultation'}
            /> */}






            <View style={styles.containerBodyMain}>

            <View style={width > 1000 ? styles.containerbox : styles.containerboxMobile}>
                    <View style={styles.containerInsideHeader}>
                        <Text style={[styles.textHeader,{fontSize:20,paddingBottom:1}]}>{'Please fill your details'}</Text>
                        <Text style={[styles.textHeader,{fontSize:11,paddingVertical:2}]}>{'We will contact you very soon'}</Text>

                    </View>

                    <View style={styles.containerRightInside}>
                        <LeadFormFill />
                    </View>
                </View>




                <View style={width > 1000 ? styles.containerbox : styles.containerboxMobile}>
                    <View style={styles.containerInsideHeader}>
                        <Text style={styles.textHeader}>{'Connect with us'}</Text>
                    </View>

                    <View style={styles.containerRightInside}>
                        <LeadFormConnect />
                    </View>

                </View>

            </View>

            <View style={{alignItems:'center'}}>
            <Image source={Ad} resizeMode='contain' style={{width:'95%',maxWidth:540,height:720,}} />
            </View>









            <View style={styles.containerFooter}>

            <View style={styles.containerCallAction}>
        <Text style={styles.textCallAction} >
          Chat with us
        </Text>
          <View style={styles.containerCallActionButtons}>


          <TouchableOpacity onPress={() => Linking.openURL('https://api.whatsapp.com/send?phone=971503773805')}  >
<View> <img src={WhatsApp} width={55} /> </View>
    </TouchableOpacity>


          </View>






      </View>



                <LeadFormFooter />
            </View>

        </View>

    );
};



const styles = StyleSheet.create({

    container: {
        backgroundColor: colours.greyBG,
    },
    containerHeaderBG: {
        backgroundColor: colours.black
    },

    containerHeader: {
        width: width > 1000 ? 1280 : width,
        alignItems: 'space-around',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        backgroundColor: colours.black
    },
    containerBodyMain: {
        width: width > 1280 ? 1280 : width,
        flexDirection: width > 1000 ? 'row' : 'column',
        paddingVertical: 20,
        flexWrap: width > 1000 ? 'wrap' : 'nowrap',
        alignSelf: 'center',
    },
    containerLeft: {
        flex: 1,
        marginVertical: 10,
        paddingVertical: 10,
    },
    containerLeftInside: {
        flex: 1,
        flexDirection: width > 1000 ? 'row' : 'column',
        justifyContent: 'center',
        //marginVertical:width > 1000 ?0:50
    },
    containerRight: {
        flex: 2,
        marginVertical: 10,
        paddingVertical: 10,
    },
    containerbox: {
        flex: 1,
        //marginVertical: 10,
        //paddingVertical:10,
    },
    containerboxMobile: {
        alignSelf: 'center',
        marginVertical: 15,
    },
    containerInsideHeader: {
        width: width > 1000 ? 400 : width - 20,
        borderBottomWidth:2,
        borderColor:colours.brand,
        alignSelf: 'center',
        //marginHorizontal:20,
        backgroundColor: colours.blueGreyLight,
    },
    containerInsideHeaderRight: {
        borderBottomWidth:2,
        borderColor:colours.brand,
        marginHorizontal:width > 1000 ?  16:10,
        backgroundColor: colours.blueGreyLight,
    },
    textHeader: {
        padding: 10,
        color: colours.black,
        fontSize: 20,
        fontWeight: '500',
        fontFamily: fonts.primary,
        textAlign: 'justify',
    },
    containerRightInside: {
        flex: 1,
        justifyContent: 'center',
        flexDirection: width > 1000 ? 'row' : 'column',
    },
    containerFooter: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    containerRegister: {
        width: width > 1000 ? 400 : width - 20,
        marginTop: 10,
        paddingBottom: 10,
        alignItems: 'center',
        marginHorizontal: 14,

        backgroundColor: colours.blueGreyLight,

        shadowColor: colours.black,
        shadowOffset: { width: 0, height: 2, },
        shadowOpacity: 0.1,
        shadowRadius: 20,
        elevation: 3,
    },

    textSubmit: {
        fontSize: 20,
        color: colours.white,
        fontFamily:fonts.primary
    },

    submitButton: {
        backgroundColor: colours.brandDark,
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
        marginTop: 10,
        borderRadius: 5,

    },
    textParagraph: {
        paddingVertical: 10,
        color: colours.greyDark,
        fontSize: 15,
        lineHeight: 22,
        textAlign: 'center',
        fontFamily: fonts.primary,
    },
    containerCallAction: {
        marginTop: 50,
        backgroundColor: colours.brandDark,
        paddingVertical: 20,
    
      },
      textCallAction: {
        color: colours.white,
        fontSize: width>1000?25:20,
        fontWeight: '500',
        fontFamily: fonts.primary,
        textAlign: 'center',
      },  containerCallActionButtons: {
        backgroundColor: 'rgba(5,0,0,0.2)',
        alignSelf: 'center',
        borderBottomColor: colours.brand,
        borderBottomWidth: 2,
        paddingHorizontal: 15,
        paddingVertical: 5,
        marginTop: 10,
        borderRadius: 5
      },
      textCallActionButton: {
        color: colours.white,
        fontSize: width > 1000 ?18:15,
        fontWeight: '600',
        fontFamily: fonts.primary,
        textAlign: 'center',
      },
      containerIcon: {
        marginHorizontal: width > 1000 ? 10 : 4,
    },
});


export default LeadsForm;




