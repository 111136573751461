import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, Pressable, Button, Image, Text, TouchableOpacity, Linking, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView, ImageBackground } from 'react-native';

import colours from '../config/colours';
import imgBanner from '../assets/images/home/banner-bg.jpg'

import { useNavigation } from '@react-navigation/native';
import fonts from '../config/fonts'
const { height, width } = Dimensions.get('window')

const HomeBanner = () =>
{
    const navigation = useNavigation();


    return (

        <View style={styles.containerMain}>

            <View style={styles.containerBanner}>
                <ImageBackground source={{ uri: imgBanner }} width={'100%'} style={{ height: width>1000?500:450 }}>
                    <ImageBackground width={'100%'} style={{ height: width>1000?500:450, backgroundColor: 'rgba(5,0,0,0.7)' }}>

                        <View style={styles.containerTitle}>
                            <Text style={styles.textTitle}>PROJECTS DEVELOPMENT  & PROJECT MANAGEMENT SOLUTIONS</Text>
                            <Text style={styles.textSubTitle}>We are pioneer in Real Estate & Projects Development, {'\n'} PMO (Project Management Office), {'\n'} Project Management and Consultancy</Text>
                        </View>


                    </ImageBackground>
                </ImageBackground>

            </View>

        </View>

    );
};



const styles = StyleSheet.create({

    containerMain: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    containerBanner: {
        flex: 1,
        alignSelf: 'center'
    },

    containerTitle: {
        flex: 1,
        justifyContent: 'center',
        alignSelf: 'center',
        maxWidth:800,


    },
    textTitle: {
        color: colours.white,
        fontWeight: '500',
        textAlign:'center',
        fontSize: width>1000?40:22,
        lineHeight:width>1000?44:30,
        marginHorizontal: 10,
        padding: 5,
        fontFamily: fonts.primary
    },
    textSubTitle: {
        marginTop:20,
        color: colours.white,
        fontWeight: '300',
        lineHeight:width>1000?34:20,
        textAlign:'center',
        fontSize: width>1000?22:16,
        marginHorizontal: 10,
        padding: 5,
        fontFamily: fonts.primary
    },
    hovered: {
        backgroundColor: colours.blackSecondary,
        borderRadius: 4,
        //padding:5
    },

});


export default HomeBanner;