import React from 'react'
import { Text, View, TouchableOpacity, Dimensions, ImageBackground } from 'react-native'
import colours from '../../config/colours'
import fonts from '../../config/fonts'

const width = Dimensions.get('window').width


const ContactCard = ({ cardTopImage,title,subTitle, onPress, type = 'filled', size = 'large' }) => {
  const large = width>1000? 820:width-20
  const small = width>1000? 400:width-20
  const btnSize = size === 'large' ? large : small
  const btnBgColor = type === 'filled' ? colours.blueGreyLight : colours.black
  const btnTextColor = type === 'filled' ? colours.black : colours.white
  const btnSubTextColor = type === 'filled' ? colours.greyDark : colours.greyDark
 

  const containerCommonStyle = {
    backgroundColor: btnBgColor,
    
    width: btnSize,
    margin:10,
    height:240,
    shadowColor: colours.black,
    shadowOffset: { width: 0, height: 2, },
    shadowOpacity: 0.2,
    shadowRadius: 20,
    elevation: 2,
    }

    const topBar = {
        backgroundColor: colours.black,
        height:170,
      }


  const titleUpCommonStyle = {
    paddingTop:15,
    paddingHorizontal:20,
    paddingBottom:5,
    //color: btnTextColor,
    color: colours.white,
    fontSize: 18,
    fontWeight: '400',
    fontFamily:fonts.primary,
    textAlign: 'left',
  }
    

  const titleCommonStyle = {
    paddingTop:15,
    paddingHorizontal:20,
    paddingBottom:5,
    color: btnTextColor,
    fontSize: 20,
    fontWeight: '500',
    fontFamily:fonts.primary,
    textAlign: 'left',
  }
  const subTitleCommonStyle = {
    paddingTop:5,
    paddingHorizontal:20,
    paddingBottom:15,    
    color: btnSubTextColor,
    fontSize: 16,
    textAlign: 'left',
    fontFamily:fonts.primary,

  }


  return (
      <View style={containerCommonStyle}>
          <View style={topBar}>
              <ImageBackground source={cardTopImage}  style={{ height: '100%' }}  >
              <View  style={{position:'absolute',bottom:0,width:'100%', backgroundColor: 'rgba(5,0,0,0.7)' }}  >
              <Text style={titleUpCommonStyle}>{title}</Text>
              </View>
              </ImageBackground>
          </View>

        <Text style={subTitleCommonStyle}>{subTitle}</Text>
      </View>
  )
}

export default ContactCard