import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import { useNavigation } from '@react-navigation/native';
import fonts from '../../config/fonts'

import Facebook from '../../assets/images/social/Facebook.svg';
import WhatsApp from '../../assets/images/social/Whatsapp.svg';
import Instagram from '../../assets/images/social/Instagram.svg';
import LinkedIn from '../../assets/images/social/Linkedin.svg';
import Twitter from '../../assets/images/social/Twitter.svg';
import Email from '../../assets/images/social/Email.svg';
import Call from '../../assets/images/social/call.svg';
import Mail from '../../assets/images/social/mail.svg';


const { height, width } = Dimensions.get('window')



const ContactSocial = () => {


    const navigation = useNavigation();
    return (

                    <View style={styles.container}>


                    <View style={[styles.containerHeader,{paddingLeft:20}]}>
                    <TouchableOpacity onPress={() => Linking.openURL('mailto:info@pdcconsult.com?subject=Support&body=Hi')} >

                            <Text numberOfLines={1} style={[styles.textHeader,{paddingBottom:20}]} >
                                {'\n'}<img src={Mail} width={25} />{'     '}info@pdcconsult.com 
                            </Text>
                            </TouchableOpacity>


                            <TouchableOpacity onPress={() => Linking.openURL('tel:+9714275734')} >

                            <Text numberOfLines={1} style={styles.textHeader} >
                            <img src={Call} width={25} />{'    '}+971 4275 734  {'\n'}{'\n'}
                            </Text>
                            </TouchableOpacity>
                        </View>



                        <View style={styles.containerRow}>

   
                        <View style={[styles.containerHeader,{paddingRight:20}]}>
                            <Text numberOfLines={1} style={styles.textSubHeader} >
                                WhatsApp
                            </Text>
                        </View>

                            <TouchableOpacity onPress={() => Linking.openURL('https://api.whatsapp.com/send?phone=97142770399')} >
                                <img style={{marginHorizontal:20,}} src={WhatsApp} width={40} />
                            </TouchableOpacity>

                        </View>


                        





                        <View style={styles.containerRow}>

                        <View style={styles.containerHeader}>
                            <Text numberOfLines={1} style={styles.textSubHeader} >
                                Follow Us 
                            </Text>
                        </View>

                        <TouchableOpacity onPress={() => Linking.openURL('https://www.linkedin.com/company/pdc-consult')}  >
                        <View style={styles.containerIcon}> <img src={LinkedIn} width={35} /> </View>
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => Linking.openURL('https://www.facebook.com/Pdcconsult')} >
                            <View style={styles.containerIcon}> <img src={Facebook} width={35} /></View>
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => Linking.openURL('https://twitter.com/pdcconsult')}>
                            <View style={styles.containerIcon}>  <img src={Twitter} width={35} /></View>
                            </TouchableOpacity>

                            <TouchableOpacity  onPress={() => Linking.openURL('https://www.instagram.com/pdcconsultuae/')} >
                            <View style={styles.containerIcon}>  <img src={Instagram} width={35} /></View>
                            </TouchableOpacity>


                        </View>






                    </View>

    );
};



const styles = StyleSheet.create({
    container:{
        width:width>1000? 400:width-20,
        marginTop:10,
        paddingBottom:10,

        backgroundColor: colours.blueGreyLight,

        shadowColor: colours.black,
        shadowOffset: { width: 0, height: 2, },
        shadowOpacity: 0.1,
        shadowRadius: 20,
        elevation: 3,
    },
    containerRow: {
        paddingHorizontal:10,
        paddingVertical:10,
        flexDirection: 'row',
    },
    containerIcon: {
        marginHorizontal: width>1000?10:4,
    },

    containerHeader: {
        marginTop:5,
        paddingLeft:15
    },

    textHeader: {
        paddingLeft:10,
        fontSize: 18,
        color: colours.greyDark,
        fontWeight: '500',
        lineHeight:27, 
    },
    textSubHeader: {
        paddingLeft:10,
        fontSize: 16,
        color: colours.greyDark,
        fontWeight: '300',
        lineHeight:27, 
    },
  



});


export default ContactSocial;