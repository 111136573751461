import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import { useNavigation } from '@react-navigation/native';

import HomeCard from '../../components/homeCard';



import cardTop1 from '../../assets/images/home/cartop-pm7.jpg'
import cardTop2 from '../../assets/images/home/cartop-pm1.jpg'
import cardTop3 from '../../assets/images/home/cartop-pm2.jpg'
import cardTop4 from '../../assets/images/home/cartop-pm3.jpg'
import cardTop5 from '../../assets/images/home/cartop-pm4.jpg'
import cardTop from '../../assets/images/home/cartop-pm5.jpg'
import cardTop8 from '../../assets/images/home/cartop-pm8.jpg'




const { height, width } = Dimensions.get('window')

const HomeBodyOne = () =>
{

  const navigation = useNavigation();

  return (

    <View style={width > 1000 ? styles.containerBody : styles.containerBodyMobile}>



      <HomeCard
        cardTopImage={{ uri: cardTop1 }}
        title={'PMO (Project Managment Office)'}
        subTitle={'Improve your Project and Portfolio Performance'}
        size={'small'}
      />
      <HomeCard
        cardTopImage={{ uri: cardTop2 }}
        title={'PM TRAINING & DEVELOPMENT'}
        subTitle={'Programs that Transform the Way People Work'}
        size={'small'}
      />
      <HomeCard
        cardTopImage={{ uri: cardTop3 }}
        title={'FAST TRACK VALUE'}
        subTitle={'Projects Development that Provides Value for Investment'}
        size={'small'}

      />
      <HomeCard
      onPress={() => navigation.navigate('About')}
        cardTopImage={{ uri: cardTop4 }}
        title={'Intensive PM Extertise'}
        subTitle={'Learn Who is Behind the Success'}
        size={'small'}

      />
      <HomeCard
        cardTopImage={{ uri: cardTop }}
        type=''
        title={'Why PDC CONSULT'}
        subTitle={'We deliver clear improvements in business performance from the Commercial and Technical support we provide. ..read more.'}
        size={'large'}

      />
            <HomeCard
        cardTopImage={{ uri: cardTop5 }}
        title={'SERVICES'}
        subTitle={'Explore our range of Services & Solutions'}
        size={'small'}
        onPress={() => navigation.navigate('Services')}

      />
      <HomeCard
      onPress={() => navigation.navigate('#')}
        cardTopImage={{ uri: cardTop8 }}
        title={'Research & Studies'}
        subTitle={'From Feasibility to Market Researchs'}
        size={'small'}

      />
    </View>




  );
};



const styles = StyleSheet.create({

  containerBody: {
    flex: 2,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start'
    //justifyContent:'center',
  },
  containerBodyMobile: {
    flexDirection: 'column',
    alignContent: 'flex-start'
    //justifyContent:'center',
  },
  containerTitle: {
    flex: 1,
    margin: 15,
    padding: 5,
  },
  textTitle: {
    color: colours.brand
  }

});


export default HomeBodyOne;