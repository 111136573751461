import React, { useState, useEffect } from 'react';
import {ScrollView, StyleSheet, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';
import aboutHeader from '../../assets/images/home/cartop-pm7.jpg'
import fonts from '../../config/fonts';
import PageBanner from '../../components/pageBanner';


import mdImage from '../../assets/images/home/md.jpg'
import pdcOrganogram from '../../assets/images/home/pdcconsultOrganogram.png'



const { height, width } = Dimensions.get('window')

const About = () => {

    useEffect(() =>
    {
            window.scrollTo(0, 0)
        //firebase.analytics();
        // eslint-disable-next-line
    }, []);

    const navigation = useNavigation();


const PageTexts = {
    title1:'About PDC CONSULT',
    paragraph1:'PDC Consult – takes pride in being one of the few locally bred professional practice firms, established in accordance with the decrees of the UAE, and specializes in project management, quantity surveying and cost management consultancy services. Since its inception PDC has commendably had a remarkable growth not only manifested in the financial indicators but more importantly in the broader client base, diversity of projects undertaken and staff growth. \n\nWe contribute our V.I.P clients to Expand and develop their projects. We assist clients to manage change within their industries, cope with the rapid changes affecting their practices today. \n\nWe serve as a resource for information on the management of a consulting practice to help our clients to Capitalize and Market their Mega Projects. We help our clients through our international Network and Joint Ventures.',
    title2:'FOUNDER - Marwa Abdelaziz',
    paragraph2:'\nMarwa is a pioneer and expert in developing projects to build revenue, profits and corporate visibility  with extensive experience in all facets of  projects starting with site identification, developing and overseeing the implementation of the feasibility plans for projects ; actively participating in the various financial analysis , acquisition, master planning, design review and tendering.\n\nShe is in the UAE Property Development industry Since 2001 during which she has coordinated all administrative activities pertaining to construction projects with an overall budget exceeding 5 billion. \n\nMarwa holds an Architectural Engineering degree, PMP and Master in Project Management from the American Academy U.S.A',
    title3:'VISION',
    paragraph3:'To lead in the niche market of Property Development and Management Consultancy by expanding our services to cover relevant aspects of the industry.',
    title4:'MISSION',
    paragraph4:'To successfully and optimally provide our clients with professional and distinguished Projects consultancy services through a successful delivery of the current project portfolio Persistently challenging further complexity and breadth of projects.',
    title5:'TEAM',
    paragraph5:'We, at PDC, rely on our people to maintain the success of our business. We believe that the better experience PDC people have, the better service our clients will be provided and that it is the diligence of our talented individuals that will lead us to our yielding future. \n\nThe PDC team is comprised of full-time Project Executives and Managers who interact to solve problems. \n\nOur staff comes from a variety of backgrounds and has extensive experience. \n\nWe always ensure that our key people are well qualified and experienced in developed management thoughts and skills that effectively apply in project initiations, development of project lifecycles and implementations.',
}




    return (
        <SafeAreaView>
            <ScrollView>
        <View style={styles.container}>
            <View style={styles.containerHeaderBG}>


                <View style={styles.containerHeader}>
                    <Header />
                </View>
            </View>

            <PageBanner
                cardTopImage={{ uri: aboutHeader }}
                title={'About'}
            />


            <View style={styles.containerBodyPage}>
                    <Text style={styles.textHeader}>{PageTexts.title1}</Text>

                    <Text style={styles.textParagraph}>{PageTexts.paragraph1}</Text>


                    <Text style={styles.textHeader}>{PageTexts.title2}</Text>

                    <View style={{flexDirection:width > 1000 ?'row':'column'}}>

                        <View style={width > 1000 ?styles.containerImage:styles.containerImagePortrait}>
                              <Image source={{ uri: mdImage }} style={{width:'100%',height:'100%',resizeMode:'contain',marginTop:5}}  />
                              <Text style={styles.TextImageLabel}>{'Marwa Abdelaziz'}</Text>

                        </View>


                        <View style={{flex:2,paddingLeft:10}}>
                            <Text style={styles.textParagraph}>{PageTexts.paragraph2}</Text>
                            <Text style={styles.TextImageLabel}>{'\nTo learn more about Marwa Abdelaziz, Please visit'}<TouchableOpacity onPress={() => Linking.openURL('https://marwaabdelaziz.com')}>
                            <Text style={{color:colours.brand}}>  www.marwaabdelaziz.com</Text>
                                 </TouchableOpacity></Text>

                        </View>


                    </View>


                    <Text style={styles.textHeader}>{PageTexts.title3}</Text>
                    <Text style={styles.textParagraph}>{PageTexts.paragraph3}</Text>

                    <Text style={styles.textHeader}>{PageTexts.title4}</Text>
                    <Text style={styles.textParagraph}>{PageTexts.paragraph4}</Text>


                    <Text style={styles.textHeader}>{PageTexts.title5}</Text>
                    <Text style={styles.textParagraph}>{PageTexts.paragraph5}</Text>


                    <View style={width > 1000 ?{width:'100%',height:700}:styles.containerImagePortrait}>
                              <Image source={{ uri: pdcOrganogram }} style={{width:'100%',height:'100%',resizeMode:'contain'}}  />
                              <Text style={styles.TextImageLabel}>{'Organizational Chart'}</Text>
                        </View>

            </View>



            

            <View style={styles.containerFooter}>
                <Footer />
            </View>

       

        </View>
</ScrollView>
        </SafeAreaView>

    );
};



const styles = StyleSheet.create({

    container: {
        minHeight: '100vh',
        backgroundColor: colours.greyBG,
    },
    containerHeaderBG: {
        backgroundColor: colours.black
    },

    containerHeader: {

        width: width > 1000 ? 1280 : width,
        alignItems: 'space-around',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        backgroundColor: colours.black
    },

    containerBody: {
        //flex: 1,
        paddingVertical:width > 1000 ?20:5,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignSelf: 'center',
        alignItems: 'center',
        width: width > 1000 ? 1280 : width,
    },
    containerBodyPage: {
        alignSelf:'center',
        marginVertical:20,
        backgroundColor: colours.blueGreyLight,
        paddingTop:25,
        paddingBottom:20,

        width: width>1000? 1100:width-20,
        //maxWidth:1280,
        marginHorizontal:80,
        paddingHorizontal:width>1000?100:25,
        //shadowColor: colours.black,
        //shadowOffset: { width: 0, height: 3, },
        //shadowOpacity: 0.2,
        //shadowRadius: 20,
        //elevation: 5,
    },
    textHeader:{
        paddingTop:20,    
        paddingBottom:10,
        color: colours.black,
        fontSize: 18,
        fontWeight: '500',
        fontFamily:fonts.primary,
        textAlign: 'justify',
        borderBottomWidth:2, 
        borderColor:colours.brand
    },
    textParagraph:{
        paddingVertical:10,    
        color: colours.greyDark ,
        fontSize: 15,
        lineHeight:22,
        textAlign: 'justify',
        fontFamily:fonts.primary,
    },
    textSubHeader:{
        paddingTop:10,
        paddingBottom:10,
        color: colours.black,
        fontSize: 15,
        fontWeight: '500',
        fontFamily:fonts.primary,
        textAlign: 'justify',
    },
    containerImage: {
       flex:1,
        height:300, 
        alignSelf:'center'
    },
    containerImagePortrait: {
        flex:1,
         height:350, 

     },

    Image: {
        height:300,
        width:200,
        resizeMode:'contain',
        marginHorizontal:10,
       // marginVertical:width>1000?25:5,  
    },
    TextImageLabel:{
        color: colours.greyDark ,
        fontSize: 14,
        textAlign: 'center',
        fontFamily:fonts.primary,
    },
    containerFooter: {
        flex: 1,
        justifyContent: 'flex-end',
    },

});


export default About;




