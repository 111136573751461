import React from 'react'
import { Text, View, TouchableOpacity, Dimensions, ImageBackground } from 'react-native'
import colours from '../../config/colours'
import fonts from '../../config/fonts'

const width = Dimensions.get('window').width


const ServiceCard = ({ cardTopImage,title,subTitle, onPress, type = 'filled', bordered = false, size = 'large' }) => {
  const large = width>1000? 820:width-20
  const small = width>1000? 540:width-20
  const btnSize = size === 'large' ? large : small
  const btnBgColor = type === 'filled' ? colours.blueGreyLight : colours.black
  const btnTextColor = type === 'filled' ? colours.black : colours.white
  const btnSubTextColor = type === 'filled' ? colours.greyDark : colours.greyDark

  //const btnBorderRadius = bordered ? 30 : 5

  const containerCommonStyle = {
    
    backgroundColor: btnBgColor,
    width: btnSize,
    //marginHorizontal:5,
    marginVertical:10,
    height:320,
    shadowColor: colours.black,
    shadowOffset: { width: 0, height: 3, },
    shadowOpacity: 0.2,
    shadowRadius: 20,
    elevation: 5,
    }

    const topBar = {
        backgroundColor: colours.black,
        height:200,
        borderBottomWidth:3,
        borderColor:colours.brand,
      }
    

  const titleCommonStyle = {
    paddingTop:15,
    paddingHorizontal:20,
    paddingBottom:5,
    color: btnTextColor,
    fontSize: 20,
    fontWeight: '600',
    fontFamily:fonts.primary,
    textAlign: 'left',
  }
  const subTitleCommonStyle = {
    paddingTop:5,
    paddingHorizontal:20,
    paddingBottom:15,    
    color: btnSubTextColor,
    fontSize: 14,
    textAlign: 'left',
    fontFamily:fonts.primary,

  }




  const border = type === 'outlined' && { borderColor: '#e7e7e7', borderWidth: 2 }

  return (
    <TouchableOpacity onPress={onPress} activeOpacity={0.7}>
      <View style={[containerCommonStyle, border]}>
          <View style={topBar}>
              <ImageBackground source={cardTopImage}  style={{ height: '100%' }}  />
          </View>
        <Text style={[titleCommonStyle]}>{title}</Text>
        <Text   style={[subTitleCommonStyle]}>{subTitle}</Text>
      </View>
    </TouchableOpacity>
  )
}

export default ServiceCard