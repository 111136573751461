import React from 'react';
import HomeNavigator from './pages/homeNavigator';
import { SafeAreaProvider } from 'react-native-safe-area-context';

const App = () => {
      return (
           
            <SafeAreaProvider> <HomeNavigator /></SafeAreaProvider>
      )
}
export default App ;


