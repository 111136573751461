import React, { useState, useEffect,useCallback } from 'react';
import { StyleSheet,View,Pressable,Button, Image, Text, TouchableOpacity,Linking, Dimensions, FlatList, ActivityIndicator, RefreshControl,ScrollView,SafeAreaView } from 'react-native';

import colours from '../config/colours';
import Pdcc from '../assets/images/logo/pdcc_w.svg'
import MenuHamburger from '../assets/images/menu.svg'
import Call from '../assets/images/social/call.svg'
import Modal from 'react-native-modal';

import { useNavigation } from '@react-navigation/native';
import fonts from '../config/fonts'
import Menu from './menu';
const { height, width } = Dimensions.get('window')

const Header = () => {
  const navigation = useNavigation();
  const [isModalVisibleContact, setModalVisibleContact] = useState(false);
  const toggleModal = () => {
    setModalVisibleContact(!isModalVisibleContact);
  };
    return (
      <View style={styles.container}>

      <View style={styles.containerMain}>


        <View style={styles.containerLogo}>
            <TouchableOpacity onPress={() => navigation.navigate('Home')}>
                    <img src={Pdcc} width={width>1000?120:90} height={width>1000?70:50} />
                    </TouchableOpacity>
        </View>



{ width >1000 &&
        <View style={styles.containerMenu}>
          <Pressable style={(state) =>  state.hovered && styles.hovered} >    
          <TouchableOpacity onPress={() => navigation.navigate('About')}>
              <Text style={styles.containerMenuText}> About</Text>
              </TouchableOpacity>
            </Pressable>
            <Pressable style={(state) =>  state.hovered && styles.hovered} >    
          <TouchableOpacity onPress={() => navigation.navigate('Services')}>
              <Text style={styles.containerMenuText}> Services</Text>
              </TouchableOpacity>
            </Pressable>

            <Pressable onPress={() => //navigation.navigate('home') 
          null }
          style={(state) =>  state.hovered && styles.hovered}
          >    
              <Text style={styles.containerMenuText}> Projects</Text>
            </Pressable>

            <Pressable onPress={() => //navigation.navigate('home') 
          null }
          style={(state) =>  state.hovered && styles.hovered}
          >    
              <Text style={styles.containerMenuText}> Resources</Text>
            </Pressable>

            <Pressable style={(state) =>  state.hovered && styles.hovered} >    
          <TouchableOpacity onPress={() => navigation.navigate('Register')}>
              <Text style={styles.containerMenuText}> Registration</Text>
              </TouchableOpacity>
            </Pressable>

            <Pressable onPress={() => //navigation.navigate('home') 
          null }
          style={(state) =>  state.hovered && styles.hovered}
          >              <TouchableOpacity onPress={() => navigation.navigate('Contact')}>

              <Text style={styles.containerMenuText}> Contact</Text>
              </TouchableOpacity>
            </Pressable>



          </View>
          }





{ width <1000 &&
        <View style={styles.containerMenuMobile}>

          <TouchableOpacity onPress={() => navigation.navigate('Contact')}>
          <img src={Call} width={30} height={25} />
              </TouchableOpacity>
            <TouchableOpacity  onPress={() => {setModalVisibleContact(!isModalVisibleContact)}}>
            <img src={MenuHamburger} width={40} height={30} />
            </TouchableOpacity>
          </View>
          }






          </View>




          <Modal 
isVisible={isModalVisibleContact} 
transparent={true}
onBackdropPress={()=>setModalVisibleContact(!isModalVisibleContact)}
animationIn={'fadeInRight'}
animationOut={'slideOutRight'}
style={styles.modalContainer}
>
<View>
  <Menu
  toggleModal={toggleModal}
  />
</View>
</Modal>



      </View>

    );
  };



const styles = StyleSheet.create({
  container: {
    backgroundColor:colours.black,
  },
  containerMain: {
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-between'
  },
  containerLogo:  {
    paddingLeft:width>1000?20:5,

  },
  containerMenu:  {
    paddingRight:20,
    flexDirection:'row',
  },
  containerMenuMobile:  {
    paddingRight:20,
    flexDirection:'row',
    alignItems:'center'
  },
  containerMenuText: {
    color:colours.white,
    fontWeight:'400',
    fontSize:16,
    marginHorizontal:10,
    padding:5,
    fontFamily:fonts.primary
  },
  hovered: {
    backgroundColor:colours.blackSecondary,
    borderRadius:4,
    //padding:5
  },
  modalContainer: {
    flex:1,
    flexDirection: 'column',
    backgroundColor: colours.black,
    justifyContent:'flex-start',
    //width:width>1000? 800: width-20,
    //alignSelf:'center',
    //paddingVertical:40,
    margin:0,
},

});
  

export default Header;