import React, { useState, useEffect } from 'react';
import { StyleSheet,ScrollView, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';

import HomeBanner from '../../components/homeBanner';
import HomeBodyOne from './homeBodyOne';
import HomeBodyUpdates from './homeBodyUpdates';

const { height, width } = Dimensions.get('window')

const Home = () => {

  const [dimensions, setDimensions] = React.useState({ 
       height: window.innerHeight,
       width: window.innerWidth
      })
      const handleResize = () => {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);

    //firebase.analytics();
    // eslint-disable-next-line
  }, []);

  const navigation = useNavigation();

  return (
    <SafeAreaView>
<ScrollView>
    <View style={styles.container}>
            <View style={styles.containerHeaderBG}>


      <View style={styles.containerHeader}>
        <Header />
      </View>
      </View>

      <HomeBanner />
      {width > 999 &&
        <View style={styles.containerBody}>
          <HomeBodyOne />
          <HomeBodyUpdates />

        </View>
      }

      {width < 1000 &&
        <View style={styles.containerBodyMobile}>
          <HomeBodyOne />

          <HomeBodyUpdates />

        </View>
      }




      <View style={styles.containerFooter}>
        <Footer />
      </View>

    </View>
    </ScrollView>

    </SafeAreaView>

  );
};



const styles = StyleSheet.create({

  container: {
    flex:1,
    backgroundColor: colours.greyBG ,
  },
  containerHeaderBG: {
    backgroundColor:colours.black
  },

  containerHeader: {
    width: width > 1000 ? 1280 : width,
    alignItems:'space-around',
    alignSelf:'center',
    justifyContent: 'flex-start',
    backgroundColor:colours.black
  },

  containerBody: {
    width: width > 1280 ? 1280 : width,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignSelf: 'center',
  },

  containerBodyMobile: {
    flex:1,
    alignItems:'center'
  },

  containerFooter: {
    //flex: 1,
    justifyContent: 'flex-end',
  },

});


export default Home;




