import service01 from '../assets/images/services/services01.jpg'
import service02 from '../assets/images/services/services02.jpg'
import service03 from '../assets/images/services/services03.jpg'
import service04 from '../assets/images/services/services04.jpg'
import service05 from '../assets/images/services/services05.jpg'
import service06 from '../assets/images/services/services06.jpg'
import service07 from '../assets/images/services/services07.jpg'
import service08 from '../assets/images/services/services08.jpg'
import service09 from '../assets/images/services/services09.jpg'
import service10 from '../assets/images/services/services10.jpg'


const PageData = {

    pageTitleText:'Services',

    //banner images
    serviceImage01: service01,
    serviceImage02: service02,
    serviceImage03: service03,
    serviceImage04: service04,
    serviceImage05: service05,
    serviceImage06: service06,
    serviceImage07: service07,
    serviceImage08: service08,
    serviceImage09: service09,
    serviceImage10: service10,

    //services main page

    title: 'OUR SERVICES',

    header1: 'Your Projects are in Safe Hands',
    paragraph1: 'PDC Consult can help your project reach your dreams, save budget, deliver on time and bring best ROI. We are rich in experience, knowledege and expert using innovative strategy and modern technology to deliver you the best in class experience',
    services01: 'Projects Development and \nClient Representation',
    servicesDescription01: 'Learn more',

    services02: 'PMO Deployment, \nOperation & Enhancement',
    services03: 'Projects Review & Recovery',
    services04: 'Project Management Consultancy',
    services05: 'Value Engineering Services',
    services06: 'Contracts & Procurement Management',
    services07: 'Claims and Disputes Management',
    services08: 'Architectural Design and \nUrban Planning',
    services09: 'Interiors Design \nand Fit-Out Management',
    services10: 'Feasibility Studies & \nReal Estate Market Research',


    //service page 01
    service01: 'Projects Development and Client Representation',
    serviceDescription01: 'PDC Consult offers a variety of services in all three phases of a project including:',
    serviceParagraph01: 'Pre-Construction\n• Program and Requirements Definition\n• Site Selection and Planning\n• Design Consultants’ Selection\n• Cost Estimating\n• Regulatory/Approval Management\n• Feasibility Studies\n• Financing Analysis and Planning\n• Project Planning and Scheduling\n• Design Review and Management\n• Tender Management\n\nConstruction\n\n• Cost Control and Reporting\n• Contract Administration\n• Safety Management\n• Procurement Management\n• Scheduling Control and Reporting\n• Construction Supervision\n• Claims Management\n\nPost-Construction\n\n• Operation and Maintenance Planning\n• Move-In Assistance\n• Record Drawing Management\n• Start-up Assistance\n• Warranty Review'

}
export default PageData;