

import React, { useState } from "react";
import { View, Text, Image, TextInput, ImageBackground, Dimensions, Button, StyleSheet, ScrollView, TouchableOpacity, Linking } from "react-native";
import colours from '../../config/colours'
import "typeface-montserrat";
import { firebase } from '../../config/firebase'
import fonts from '../../config/fonts'


import WhatsApp from '../../assets/images/social/Whatsapp.svg';
import Email from '../../assets/images/social/Email.svg';



const { height, width } = Dimensions.get('window')

const ContactDetails = () => {


    const [onFocus, setOnFocus] = useState(false)
    const [onBlur, setOnBlur] = useState(false)

    const [onFocusNumber, setOnFocusNumber] = useState(false)
    const [onBlurNumber, setOnBlurNumber] = useState(false)

    const [onFocusMessage, setOnFocusMessage] = useState(false)
    const [onBlurMessage, setOnBlurMessage] = useState(false)

    const [nameText, setNameText] = useState('')
    const [numberText, setNumberText] = useState('')
    const [messageText, setMessageText] = useState('')

    const [subHeaderTextHeight, setSubHeaderTextHeight] = useState(0)
    const whatsappURL = 'https://api.whatsapp.com/send?phone=971503773805'


    const contactUsData = () => {

        var currentDate = new Date();
        var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
        var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
        var createdDateTo = currentDate.getFullYear() + twoDigitMonth + twoDigitDate;
        const docid = createdDateTo + "-" + new Date().getTime()



        firebase.firestore().collection('messages')
            .doc(docid).set({
                name: nameText,
                number: numberText,
                createdDtm: new Date(),
                message: messageText
            })
            .then(alert('Sent Successfully, We will contact you as soon as possible. Thank You'))
            .then(setNameText(''), setNumberText(''), setMessageText(''))



    }
    return (

        <View style={styles.Container}>

            <View style={styles.textContainer}>
                <TextInput
                    adjustsFontSizeToFit={true}
                    allowFontScaling={true}
                    value={nameText}
                    placeholder={onFocus ? '' : 'Name'}
                    onChangeText={(editedText) => { setNameText(editedText) }}
                    onFocus={() => { setOnFocus(true), setOnBlur(false) }}
                    onBlur={() => { setOnBlur(true), setOnFocus(false) }}
                    onKeyPress={(nativeEvent) =>
                    {
                        if (nativeEvent.key === 'Backspace') {
                            //setSubHeaderTextHeight(0) 
                        }
                    }}
                    placeholderTextColor={colours.greyFive}
                    style={{
                        
                        minWidth:350,
                        maxWidth: 500,

                        textAlign: 'left',
                        fontFamily:fonts.primary,
                        marginBottom: 10,
                        marginTop: 2,
                        color: colours.greyDark,
                        fontSize: '.8rem',
                        fontWeight: '500',
                        borderWidth: 1,
                        borderColor: onFocusMessage ? colours.blackSecondary : colours.greyFour,
                        borderRadius: 5,
                        padding: 10

                    }}
                />
                <TextInput
                    adjustsFontSizeToFit={true}
                    allowFontScaling={true}
                    value={numberText}
                    placeholder={onFocusNumber ? '' : 'Contact/WhatsApp No'}
                    keyboardType='numeric'
                    maxLength={15}

                    onChangeText={(editedText) => { setNumberText(editedText) }}
                    onFocus={() => { setOnFocusNumber(true), setOnBlurNumber(false) }}
                    onBlur={() => { setOnBlurNumber(true), setOnFocusNumber(false) }}
                    onKeyPress={(nativeEvent) =>
                    {
                        if (nativeEvent.key === 'Backspace') {
                            //setSubHeaderTextHeight(0) 
                        }
                    }}
                    placeholderTextColor={colours.greyFive}

                    style={{
                        
                        minWidth:350,
                        maxWidth: 500,

                        textAlign: 'left',
                        fontFamily:fonts.primary,
                        marginBottom: 10,
                        marginTop: 2,
                        color: colours.greyDark,
                        fontSize: '.8rem',
                        fontWeight: '500',
                        borderWidth: 1,
                        borderColor: onFocusMessage ? colours.blackSecondary : colours.greyFour,
                        borderRadius: 5,
                        padding: 10

                    }}
                />
                <TextInput
                    adjustsFontSizeToFit={true}
                    allowFontScaling={true}
                    value={messageText}
                    placeholder={onFocusMessage ? '' : 'Message'}
                    multiline={true}
                   
                    onContentSizeChange={(event) => { setSubHeaderTextHeight(event.nativeEvent.contentSize.height) }}
                    onChangeText={(editedText) => { setMessageText(editedText) }}
                    onFocus={() => { setOnFocusMessage(true), setOnBlurMessage(false) }}
                    onBlur={() => { setOnBlurMessage(true), setOnFocusMessage(false) }}
                    onKeyPress={(nativeEvent) =>
                    {
                        if (nativeEvent.key === 'Backspace') {
                            //setSubHeaderTextHeight(0) 
                        }
                    }}
                    placeholderTextColor={colours.greyFive}

                    style={{
                        minWidth:350,
                        maxWidth: 500,
                        textAlign: 'left',
                        fontFamily:fonts.primary,
                        marginBottom: 2,
                        marginTop: 2,
                        color: colours.greyDark,
                        fontSize: '.8rem',
                        fontWeight: '500',
                        borderWidth: 1,
                        borderColor: onFocusMessage ? colours.blackSecondary : colours.greyFour,
                        borderRadius: 5,
                        padding: 10,
                        height: Math.max(20, subHeaderTextHeight),
                        minHeight: Math.max(20, 100)



                    }}
                />

                <TouchableOpacity
                    onPress={() =>
                    {
                        isNaN(numberText) ? alert('Please Provide Contact Number') :
                            nameText.length < 2 ? alert('Please Provide Name') :
                                numberText.length < 5 ? alert('Please Provide Contact Number') :

                                    messageText.length < 3 ? alert('Please Let us know your Message') : contactUsData()

                    }
                    }
                >
                    <View style={styles.submitButton}>
                        <Text style={styles.textSubmit}>Submit</Text>
                    </View>
                </TouchableOpacity>
            </View>



        </View>

    )
}

export default ContactDetails;

const styles = StyleSheet.create({

    Container: {
        width:width>1000? 400:width-20,
        marginTop:10,
        paddingBottom:10,

        backgroundColor: colours.blueGreyLight,
        shadowColor: colours.black,
        shadowOffset: { width: 0, height: 2, },
        shadowOpacity: 0.1,
        shadowRadius: 20,
        elevation: 3,
    },

    textContainer: {
        paddingTop: 10,
        marginHorizontal: 10,
        alignItems: 'center',
    },

    textSubmit: {
        fontSize: 20,
        color: colours.white,
        fontFamily:fonts.primary

    },

    submitButton: {
        backgroundColor: colours.brandDark,
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
        marginTop: 10,
        borderRadius:5,

    },

});


