

import React, { useState, useEffect,useContext } from 'react';
import { ScrollView ,View, Text, Dimensions, StyleSheet,TouchableOpacity,Linking } from "react-native";
import colours from '../config/colours'
import { useNavigation } from '@react-navigation/native';
import fonts from '../config/fonts';
import Pdcc from '../assets/images/homelogo.svg'

const { height, width } = Dimensions.get('window')

const Menu = ({ route,  toggleModal}) => {
    const navigation = useNavigation();

  const [isModalVisibleContact, setModalVisibleContact] = useState(false);
  const [isModalVisible, setModalVisible] = useState(true);













  const goHome = () => {
    setModalVisible(!isModalVisible);
    navigation.goBack();
  };
  const Home =() => {
    navigation.navigate('Home');
    setModalVisible(!isModalVisible);

  }
return(
 
    <ScrollView >



                <TouchableOpacity 
                //onPress={() => {Home(),toggleModal()}} 
                onPress={toggleModal} 
                >
                    
                    <View style={styles.headerItemLeft}>
                    <Text style={styles.closeText}>X</Text>
                    </View>

                    </TouchableOpacity>



                    <View style={styles.containerLogo}>
            <TouchableOpacity onPress={() => {toggleModal() ,navigation.navigate('Home')}}>
                    <img src={Pdcc} width={width>1000?80:70} height={width>1000?50:40} />
                    </TouchableOpacity>
        </View>


  <TouchableOpacity
      onPress={() => {toggleModal() ,navigation.navigate('About')}} 

  >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> ABOUT </Text>
  </View>
  </TouchableOpacity>

  <TouchableOpacity 
   onPress={() => {toggleModal() ,navigation.navigate('Services')}} 
    >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> SERVICES </Text>
  </View>
  </TouchableOpacity>

  <TouchableOpacity 
   onPress={() => {toggleModal() ,navigation.navigate('Register')}} 
    >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> REGISTRATION </Text>
  </View>
  </TouchableOpacity>



  <TouchableOpacity
    onPress={() => {toggleModal() ,navigation.navigate('Contact')}} 

  >
  <View style={styles.menuContainer}>
            <Text style={styles.menuText}> CONTACT US </Text>
  </View>
  </TouchableOpacity>
  


   
            </ScrollView >

    )};

export default Menu;

const styles = StyleSheet.create({

    menuContainer: {
        //paddingVertical:5,
        //marginVertical:'.5rem',
        width:'12rem',
        alignSelf:'center',
        alignItems:'center',
        borderBottomColor:colours.black,
        borderBottomWidth:.5

    },


 
    closeContainer: {
        backgroundColor: colours.primary,
        borderRadius: 5,
        marginTop:20,
        paddingHorizontal: 20,
        paddingVertical: 20,
        alignSelf:'center',
    },
 
    closeText: {
        fontSize: '1.7rem',
        fontWeight:'200',
        color: colours.white,
        fontFamily: fonts.primary,

    },



    menuText: {
        marginVertical: '.6rem',
        //marginHorizontal: 20,
        color: colours.white,
        fontSize: '1.2rem',
        fontWeight: '300',
        fontFamily: fonts.primary,

    },
    menuTextCS: {
      marginVertical: '.6rem',
      //marginHorizontal: 20,
      color: colours.primaryLight,
      fontSize: '1.2rem',
      fontWeight: '300',
      fontFamily: fonts.primary,

  },
    headerItemLeft:
    {
      //flex: 2,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginVertical: 20,
      marginHorizontal:width>1000?40:20,
      borderWidth:1,
      alignSelf:'flex-end',
      paddingHorizontal:10,
      paddingVertical:3,
      borderRadius:3,
      borderColor:colours.primaryLight
  
    },
    containerLogo:  {
        //paddingLeft:20,
        alignItems:'center',
        marginBottom:10,
      },


});


