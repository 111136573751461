import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';
import fonts from '../../config/fonts'
import cardTop from '../../assets/images/home/cartop-pmo.jpg'

import { firebase } from '../../config/firebase'
import HomeBanner from '../../components/homeBanner';
import HomeCard from '../../components/homeCard';
import HomeCardUpdates from '../../components/homeCardUpdates';

const { height, width } = Dimensions.get('window')

const HomeBodyUpdates = () =>
{

  const navigation = useNavigation();

  return (

    <View style={styles.containerBody}>
      <View style={styles.containerTitle}>

        <Text style={styles.textTitle}> UPDATES </Text>
      </View>

      <HomeCardUpdates
        title={'Registration'}
        subTitle={'Inviting registration for Consultants, Contractors, Subcontractors'}
        size={'small'}
        onPress={() => navigation.navigate('Register') }
        />
      <HomeCardUpdates
        title={'Introducing PM Softwares & Apps'}
        subTitle={'Improved planning, scheduling and more'}
        size={'small'}
      />
       <HomeCardUpdates
        title={'PM for Non-Project Managers'}
        subTitle={'Read article '}
        size={'small'}
      />
       <HomeCardUpdates
        title={'A Success Case Study'}
        subTitle={'Learn about Stakeholder Management in Project'}
        size={'small'}
      />
       <HomeCardUpdates
        title={'PM Tips'}
        subTitle={'Secrets to Running a Successful Meeting '}
        size={'small'}
      />
       <HomeCardUpdates
        title={'Carlos Ott Architect \nMiddle East Representation'}
        subTitle={'International Award Winner Carlos Ott in Middle East'}
        size={'small'}
      />
        <HomeCardUpdates
        title={'Delivered'}
        subTitle={'Explore our projects'}
        size={'small'}
        />
    </View>

  );
};



const styles = StyleSheet.create({

  containerBody: {
    marginBottom:12,
    flex:1,
  },
  containerTitle: {
    marginTop: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    backgroundColor: colours.black,
    width: width > 1000 ? 400 : width-20
    // alignContent: 'flex-start',
    //width: '93%'
    //flex:1
  },

  textTitle: {
    color: colours.brand,
    fontFamily: fonts.primary,
    fontSize: 25,
    fontWeight: '500',
    textAlign: 'left',
    // marginTop:15,

  },

});


export default HomeBodyUpdates;




