import React from 'react';
import { StyleSheet, View, Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView, Linking } from 'react-native';

import colours from '../config/colours';
import fonts from '../config/fonts'
import { useNavigation,useRoute } from '@react-navigation/native';

import Facebook from '../assets/images/social/Facebook.svg';
import WhatsApp from '../assets/images/social/Whatsapp.svg';
import Instagram from '../assets/images/social/Instagram.svg';
import LinkedIn from '../assets/images/social/Linkedin.svg';
import Twitter from '../assets/images/social/Twitter.svg';
import Email from '../assets/images/social/Email.svg';
import Call from '../assets/images/social/call.svg';
import Mail from '../assets/images/social/mail.svg';

const { height, width } = Dimensions.get('window')

const FooterWidgets = () =>{
  const navigation = useNavigation();
  const route = useRoute()

  return (
    <View >
{route.name!=='Contact' &&
      <View style={styles.containerCallAction}>
        <Text style={styles.textCallAction} >
          How Can We Help You ?
        </Text>
        <TouchableOpacity onPress={() => navigation.navigate('Contact')}>
          <View style={styles.containerCallActionButtons}>
            <Text style={styles.textCallActionButton} >
              Get in Touch
            </Text>
          </View>
        </TouchableOpacity>
      </View>
}
{route.name=='Contact' &&
<View style={[styles.containerCallAction,{height:70}]}></View>
}

<View style={width > 1000 ? styles.containerMain:styles.containerMainMobile}>
      <View style={styles.container}>

        <TouchableOpacity onPress={() => navigation.navigate('About')}>
          <Text style={styles.textFooterLinks} >
            About Us
          </Text>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigation.navigate('Services')}>
          <Text style={styles.textFooterLinks} >
            Our Services
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate('Register')}>
          <Text style={styles.textFooterLinks} >
            Registration
          </Text>
        </TouchableOpacity>
        {route.name!=='Contact' &&
        <TouchableOpacity onPress={() => navigation.navigate('Contact')}>
          <Text style={styles.textFooterLinks} >
            Contact Us
          </Text>
        </TouchableOpacity>
}
        </View>


        <View style={styles.containerSocial}>

                
                <TouchableOpacity onPress={() => Linking.openURL('https://www.linkedin.com/company/pdc-consult')}  >
                  <View style={styles.containerIcon}> <img src={LinkedIn} width={30} /> </View>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => Linking.openURL('https://www.facebook.com/Pdcconsult')} >
                  <View style={styles.containerIcon}> <img src={Facebook} width={30} /></View>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => Linking.openURL('https://twitter.com/pdcconsult')}>
                  <View style={styles.containerIcon}>  <img src={Twitter} width={30} /></View>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => Linking.openURL('https://www.instagram.com/pdcconsultuae/')} >
                  <View style={styles.containerIcon}>  <img src={Instagram} width={30} /></View>
                </TouchableOpacity>

        </View>
</View>



    </View>

  );
};



const styles = StyleSheet.create({

  containerCallAction: {
    marginTop: 50,
    backgroundColor: colours.brandDark,
    paddingVertical: 20,

  },
  textCallAction: {
    color: colours.white,
    fontSize: width>1000?25:20,
    fontWeight: '500',
    fontFamily: fonts.primary,
    textAlign: 'center',
  },
  containerCallActionButtons: {
    backgroundColor: 'rgba(5,0,0,0.2)',
    alignSelf: 'center',
    borderBottomColor: colours.brand,
    borderBottomWidth: 2,
    paddingHorizontal: 15,
    paddingVertical: 5,
    marginTop: 10,
    borderRadius: 5
  },
  textCallActionButton: {
    color: colours.white,
    fontSize: width > 1000 ?18:15,
    fontWeight: '600',
    fontFamily: fonts.primary,
    textAlign: 'center',
  },

  textFooterLinks: {
    fontSize: width > 1000 ? 18 : 18,
    lineHeight:27,
    color: colours.blackSecondary,
    textAlign: 'center',
    fontWeight: '400',
    fontFamily: fonts.primary,
    paddingHorizontal: 20,
  },
  containerMain: {
    backgroundColor: colours.greySecond,
    flexDirection: 'row',
    justifyContent:'center',
  },
  
  containerMainMobile: {
    backgroundColor: colours.greySecond,
    flexDirection: 'column',
    alignItems:'center',
    paddingBottom:20,

  },
  container: {
    backgroundColor: colours.greySecond,
    flexDirection: width > 1000 ?'row':'column',
    alignItems:'center',
    paddingVertical:20
  },
  containerIcon: {
    marginHorizontal: 4,
    alignItems: 'center'
  },
  containerSocial: {
    backgroundColor: colours.greySecond,
    flexDirection:'row',
    alignItems: 'center',
    paddingHorizontal:10,
  },



});


export default FooterWidgets;